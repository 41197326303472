import React, { useEffect, useState } from "react";
import "../../css/landing-page/SectionDesc.css";
import uLogo from "../../assets/images/landing-page/unnamed-logo-black.png";
import secTwo from "../../assets/images/landing-page/in-lore-and-legends-illustration.png";
import { Button, Container } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Navbar, NavbarBrand } from "reactstrap";
import Customnavbar from "../Customnavbar/Customnavbar";

function LegendLoreDesc(props) {
  const [browserClass, setbrowserClass] = useState("legend-lore-visit-but-safari");

  useEffect(() => {
    if(navigator) {
      let userAgentString = navigator.userAgent;
      if(userAgentString) {
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        if ((chromeAgent) && (safariAgent)) {
          setbrowserClass("legend-lore-visit-but");
        }
      }
    }
  }, [])
  return (
    <Container
      id="legend-lore-desc"
      style={{ display: props.isActive ? "block" : "none" }}
    >
      <Navbar>
        {/* <NavbarBrand href="/menu">
          <img
            src={uLogo}
            width={93}
            height={80}
            alt="birdsong-logo"
            className="unnamed-logo"
          />
        </NavbarBrand> */}
        <Customnavbar imglogo={uLogo} handleClose={props.handleClose} id="close-legend-lore" />
      </Navbar>
      <p className="section-heading" id="legend-lore-heading">
        In Lore and Legends
      </p>
      <p className="section-description" id="desc-two">
        Discover the fascinating world of birds in Indian mythology and history.
      </p>
      <div className="illustration">
        <img
          src={secTwo}
          width={550}
          height={700}
          alt="in-lore-and-legends-illustration"
          id="birdwoman"
        />
      </div>
      <Link style={{zIndex:1}} to={"/lore"}>
        <Button className="menu-option" id={browserClass}>
          Visit the section&nbsp;&nbsp;
          <BsArrowRight size={30} color={"black"} />
        </Button>
      </Link>
      <VscChromeClose
        size={30}
        color={"black"}
        className="close-button"
        id="close-legend-lore"
        onClick={props.handleClose}
      />
    </Container>
  );
}

export default LegendLoreDesc;
