import React from 'react';
import '../../../css/natures-symphony/Tutor.css';
import '../../../css/natures-symphony/GreyStone.css';
import '../../../css/natures-symphony/SkinEar.css'
import ear from '../../../assets/images/natures-symphony/ear.png';
import ear122 from '../../../assets/images/natures-symphony/ear122.png';
import Rectangle from '../../../assets/images/natures-symphony/Rectangle.png';
import whitelayer from '../../../assets/images/natures-symphony/whitelayer.png';
import bluelayer from '../../../assets/images/natures-symphony/bluelayer.png';

const SkinEar=()=>
{
return (
  <div className="mainSkinEar"
//     style={{display:'flex',
//    width: '84vw',
//  position: 'relative',  
//  overflow: 'hidden',
//  zIndex: '-1',
//   height:'100vh',
// backgroundColor:'#F4EACE' }}
>
   
<img className='stone' id='skinstone' src={Rectangle} alt='/' />
<img className='wlayer' id='skinwl' src={whitelayer} alt='/' />
<img className='blayer' id='skinbl'  src={bluelayer} alt='/' />
  <div className='skinEarHeading'>Why does birdsong appeal to the human ear? </div>
  <p className='skinEarPara'>Birdsong shares common elements with human music - it has a structure that involves pitch, rhythm and range. Their songs resonate with our sense of musical aesthetics. The range of frequency of most birdsongs is between 1,000 Hz and 8,000 Hz, which places them in the range of human hearing, which spans  20 Hz to 20,000 Hz.</p>
    <img className='skinEarimg showMobile' src={ear} alt='/' />
    <img className='skinEarimg hidemobile' src={ear122} alt='/' />
   </div>
);
}

export default SkinEar;
