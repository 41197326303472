import React from "react";
import HorizontalScroll from "react-scroll-horizontal";
import {
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  Button,
  NavItem,
} from "reactstrap";
import logo from "../../assets/images/songs-of-nature/nature-logo.png";
import toggler from "../../assets/images/songs-of-nature/son-menu.png";

// import menu from "../../assets/images/songs-of-nature/songs-of-nature-menu-screen.jpg";
// import menu from "../../assets/images/songs-of-nature/Frame-Birds.webp";
import menu from "../../assets/images/songs-of-nature/Frame-Birds.png";

// birds image ======
import MalabarWhistlingDesktop from "../../assets/images/songs-of-nature/Birdsimage/MalabarWhistlingDesktop (2).png";
import HimalayanThrushDesktop from  "../../assets/images/songs-of-nature/Birdsimage/HimalayanThrushDesktop (2).png";
import RedWhiskeredDesktop from "../../assets/images/songs-of-nature/Birdsimage/RedWhiskeredDesktop (2).png";
import PurpleSunbirdDesktop from "../../assets/images/songs-of-nature/Birdsimage/PurpleSunbirdDesktop (2).png";
import RustyBelliedDesktop from "../../assets/images/songs-of-nature/Birdsimage/RustyBelliedDesktop (2).png";
import WhiteRumpedDesktop from "../../assets/images/songs-of-nature/Birdsimage/WhiteRumpedDesktop.png";
import GreyWingedDesktop from "../../assets/images/songs-of-nature/Birdsimage/GreyWingedDesktop (2).png";
import PuffThroatedDesktop from "../../assets/images/songs-of-nature/Birdsimage/PuffThroatedDesktop (2).png"; 
import OrientalMagpieDesktop from "../../assets/images/songs-of-nature/Birdsimage/OrientalMagpieDesktop (2).png";
import AsiankoelDesktop from "../../assets/images/songs-of-nature/Birdsimage/AsiankoelDesktop (2).png"; 


import bino from "../../assets/images/songs-of-nature/binoculars-button.png";
import SectionMenu from "../navigation/SectionMenu";
import "../../css/songs-of-nature/SonIntroPage.css";
import { Link } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import BirdsectionMobile from "./BirdsectionMobile";
import Customnavbar from "../Customnavbar/Customnavbar";

class SonIntroPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      menuOpen: false,
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }
  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }
  render() {
    return (
      <Container id="songs-of-nature">
        {/* <Navbar id="son-nav">
          <NavbarBrand href="/menu">
            <img
              src={logo}
              width={120}
              height={100}
              alt="birdsong-logo"
              // id="named-logo"
              id="songnamedlogo"
              className="songsofnaturelogo"
            />
          </NavbarBrand>
          <Nav className="ml-auto " navbar></Nav>
          <NavItem>
            <Button
              //  id="menu-toggler"
              className="songsofnaturelogobtn"
              onClick={this.openMenu}
            >
              <img src={toggler} height={"50"} width={"50"} id="songsofnaturetogel" />
            </Button>
          </NavItem>
        </Navbar> */}
         <Customnavbar imglogo={logo}  menuicon={toggler}/>
        <Container id="son-parent-one-wrapper"></Container>
        <HorizontalScroll className="desktopshowsongsofnature" >
          <Container id="son-parent-one" style={{ width: `max-content` }}>
            <Container id="son-one">
              <span className="son-heading" id="songs">
                Songs&nbsp;
              </span>
              <span className="son-heading" id="of">
                of
              </span>
              <p className="son-heading" id="nature">
                Nature
              </p>
            </Container>
            <Container id="son-two">
              <span>
                Birdsong is one of nature's most beautiful sounds. Each songbird
                offers a range of unique vocal qualities and skills, enriching
                our environment with beautifully elaborate soundscapes.{" "}
              </span>
              <br />
              <br />
              <span>
                Discover the fascinating world of songbirds and connect with
                nature’s most gifted singers.
              </span>
            </Container>
          </Container>
          <Container id="son-parent-two">
            <Link to="/birds/1">
              <img
                src={bino}
                id="binocular"
                alt="binocular-shaped-button"
                width={93}
                height={80}
              />
              <p id="bino-button">click to view all birds</p>
            </Link>
            <img src={menu} alt="menu" id="img-map" />
            <div  style={{border:"0px solid red", height:"100%", width:"100%", position:"absolute", top:"0"}} >
              <div style={{ border: "0px solid blue", height: "100%", width: "100%", position: "relative", top: "0" }} >
                <Link to="/birds/1" ><img src={RustyBelliedDesktop} className="RustyBellied" /></Link>
                <Link to="/birds/2" ><img src={WhiteRumpedDesktop} className="WhiteRumped" /></Link>
                <Link to="/birds/3" ><img src={MalabarWhistlingDesktop} className="MalabarWhistling" /></Link>
                <Link to="/birds/4" ><img src={HimalayanThrushDesktop} className="HimalayanThrush" /></Link>
                <Link to="/birds/5" ><img src={PuffThroatedDesktop} className="PuffThroated" /></Link>
                <Link to="/birds/6" ><img src={PurpleSunbirdDesktop} className="PurpleSunbird" /></Link>
                <Link to="/birds/7" ><img src={GreyWingedDesktop} className="GreyWinged" /></Link>
                <Link to="/birds/8" ><img src={OrientalMagpieDesktop} className="OrientalMagpie" /></Link>
                <Link to="/birds/9" ><img src={AsiankoelDesktop} className="Asiankoel" /></Link>
                <Link to="/birds/10" ><img src={RedWhiskeredDesktop} className="RedWhiskered" /></Link>
              </div>
            </div>
            {/* <map name="image_map">
              <area
                alt="Oriental  Magpie Robin"
                title="Oriental  Magpie Robin"
                href="/birds/8"
                coords="366,86 329,75 295,73 233,127 170,92 146,97 220,146 200,195 304,238 278,192 320,165 328,119 340,98"
                shape="polygon"
              />
              <area
                alt="Malabar  Whistling Thrush"
                title="Malabar  Whistling Thrush"
                href="/birds/3"
                coords="678,138 607,125 564,161 368,218 446,218 506,251 493,262 540,338 586,317 549,264 607,235 636,167"
                shape="polygon"
              />
              <area
                alt="Asian Koel"
                title="Asian Koel"
                href="/birds/9"
                coords="1154,73 1081,56 961,99 791,196 656,271 677,279 800,239 877,202 1004,196 1086,155 1120,86 1163,84"
                shape="polygon"
              ></area>
              <area
                alt="Puff Throated Babbler"
                title="Puff Throated Babbler"
                href="/birds/5"
                coords="1432,185 1380,189 1320,64 1462,129 1548,146 1457,161 1408,258 1384,254 1434,189 1391,193 1322,249 1382,191 1316,60 1242,99 1313,176 1367,196 1374,198 "
                shape="polygon"
              ></area>
              <area
                alt="White  Rumped Shama"
                title="White  Rumped Shama"
                href="/birds/2"
                coords="342,282 303,262 277,262 159,342 135,374 127,396 125,417 -11,561 -52,649 -6,645 67,518 140,430 193,408 258,415 299,391 318,348 325,297 342,286 337,286 "
                shape="polygon"
              ></area>
              <area
                alt="Grey Winged Blackbird"
                title="Grey Winged Blackbird"
                href="/birds/7"
                coords="469,456 411,447 372,458 307,507 221,557 170,602 196,608 127,714 247,615 271,613 333,662 297,610 359,632 310,606 368,585 411,518 436,473 462,458 "
                shape="polygon"
              ></area>
              <area
                alt="Red whiskered Bulbul"
                title="Red whiskered Bulbul"
                href="/birds/10"
                coords="828,294 844,298 856,308 877,318 899,332 928,361 942,389 955,434 965,502 947,559 932,565 930,524 916,520 916,491 906,473 879,443 858,408 848,357 844,342 830,343 844,326 871,334"
                shape="polygon"
              ></area>
              <area
                alt="Purple Sunbird"
                title="Purple Sunbird"
                href="/birds/6"
                coords="969,337 1030,333 1051,327 1068,325 1079,325 1098,333 1124,359 1154,350 1197,329 1245,307 1275,264 1292,258 1262,312 1281,310 1281,325 1260,335 1257,372 1245,404 1253,419 1247,454 1264,469 1262,479 1249,475 1236,460 1245,421 1234,413 1219,430 1225,449 1195,488 1193,509 1176,494 1180,486 1214,456 1195,434 1150,441 1107,421 1070,378 1032,348 1006,337 1083,357 "
                shape="polygon"
              ></area>
              <area
                alt="Rusty bellied shortwing"
                title="Rusty bellied shortwing"
                href="/birds/1"
                coords="518,625 507,623 561,559 578,533 634,492 658,469 684,460 709,454 765,481 733,492 735,542 703,591 662,608 641,613 610,608 548,600 658,546 "
                shape="polygon"
              ></area>
              <area
                alt="Himalyan Thrush"
                title="Himalyan Thrush"
                href="/birds/4"
                coords="1139,540 1167,535 1189,524 1217,524 1255,546 1315,576 1434,636 1442,651 1395,649 1341,649 1337,658 1283,679 1247,716 1227,714 1242,675 1223,686 1225,651 1199,632 1187,604 1176,550 1159,544 "
                shape="polygon"
              ></area>
            </map> */}
          </Container>
        </HorizontalScroll>

        {/* ===================== mobile screen ======================================== */}
        <div className="mobileshowsongsofnature">
          <Container id="son-parent-one">
            <Container id="son-one">
              <div>
                <span className="son-heading" id="songs">
                  Songs&nbsp;
                </span>
                <span className="son-heading" id="of">
                  of
                </span>
                <p className="son-heading" id="nature">
                  Nature
                </p>
              </div>
            </Container>
            <Container id="son-two">
              <div style={{ textAlign: "center" }} >
                <p>
                  Birdsong is one of nature's most beautiful sounds. Each songbird
                  offers a range of unique vocal qualities and skills, enriching
                  our environment with beautifully elaborate soundscapes.{" "}
                </p>
                <p>
                  Discover the fascinating world of songbirds and connect with
                  nature’s most gifted singers.
                </p>
              </div>
            </Container>
          </Container>

          {/* =================== bird section ====================== */}
          <div>
            <BirdsectionMobile />
          </div>
        </div>


        <SectionMenu
          isActive={this.state.menuOpen}
          handleClose={this.closeMenu}
        />
      </Container>
    );
  }
}

export default SonIntroPage;
