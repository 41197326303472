import React, { useEffect, useState } from "react";
import "../../css/landing-page/SectionDesc.css";
import uLogo from "../../assets/images/landing-page/unnamed-logo-black.png";
import secThree from "../../assets/images/landing-page/birds-in-music-illustration.png";
import { Button, Container } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Navbar, NavbarBrand } from "reactstrap";
import Customnavbar from "../Customnavbar/Customnavbar";

function BirdsInMusicDesc(props) {
  const [browserClass, setbrowserClass] = useState("birds-in-music-visit-but-safari");

  useEffect(() => {
    if(navigator) {
      let userAgentString = navigator.userAgent;
      if(userAgentString) {
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        if ((chromeAgent) && (safariAgent)) {
          setbrowserClass("birds-in-music-visit-but");
        }
      }
    }
  }, [])
  return (
    <Container
      id="birds-in-music-desc"
      style={{ display: props.isActive ? "block" : "none" }}
    >
      <Navbar>
        {/* <NavbarBrand href="/menu">
          <img
            src={uLogo}
            width={93}
            height={80}
            alt="birdsong-logo"
            className="unnamed-logo"
          />
        </NavbarBrand> */}
        <Customnavbar imglogo={uLogo} handleClose={props.handleClose} id="close-birds-in-music" />
      </Navbar>

      <p className="section-heading" id="birds-in-music-heading">
        Birds in Music
      </p>
      <p className="section-description" id="desc-three">
        Explore how birds have inspired Indian music across time, from classical
        and folk to instrumental music.
      </p>
      <div className="illustration">
        <img
          src={secThree}
          width={620}
          height={710}
          alt="birds-in-music-illustration"
          id="instrument"
        />
      </div>
      <Link style={{zIndex:1}} to={"/birdsinmusic"}>
        <Button className="menu-option" id={browserClass}>
          Visit the section&nbsp;&nbsp;
          <BsArrowRight size={30} color={"black"} />
        </Button>
      </Link>
      <VscChromeClose
        size={30}
        color={"black"}
        className="close-button"
        id="close-birds-in-music"
        onClick={props.handleClose}
      />
    </Container>
  );
}

export default BirdsInMusicDesc;
