import React from "react";
import "../../css/landing-page/Menu.css";
import {
  Button,
  Container,
  Row,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import nLogo from "../../assets/images/landing-page/named-logo.png";
import { AiFillInstagram } from "react-icons/ai";
import { IoMail } from "react-icons/io5";
import NaturesSymphonyDesc from "./NaturesSymphonyDesc";
import LegendLoreDesc from "./LegendLoreDesc";
import BirdsInMusicDesc from "./BirdsInMusicDesc";
import SongsOfNatureDesc from "./SongsOfNatureDesc";
import Disclaimer from "./Disclaimer";
import Customnavbar from "../Customnavbar/Customnavbar";

class Menu extends React.Component {
  constructor(props) {
    super();
    this.state = {
      natureSymphonyActive:false,
      legendLoreActive:false,
      birdsInMusicActive:false,
      songsOfNatureActive:false,
      disclaimerActive: false,
      displayElements:true
    }
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  handleButtonClick(e){
    this.setState({
      displayElements:false
    })
    if(e.target.id == 'natures-symphony-but'){
      this.setState({
        natureSymphonyActive:true,
      })
    }
    else if(e.target.id == 'legend-lore-but'){
      this.setState({
        legendLoreActive: true,
      });
    } else if (e.target.id == "birds-in-music-but") {
      this.setState({
        birdsInMusicActive: true,
      });
    } else if (e.target.id == "songs-of-nature-but") {
      this.setState({
        songsOfNatureActive: true,
      });
    } else if (e.target.id == "disclaimer-button") {
      this.setState({
        disclaimerActive: true,
      });
    }
  }
  handleClose(e){
  
    if(e.currentTarget.id == 'close-birds-in-music'){
      this.setState({
        birdsInMusicActive: false,
      });
    } else if (e.currentTarget.id == "close-legend-lore") {
      this.setState({
        legendLoreActive: false,
      });
    } else if (e.currentTarget.id == "close-natures-symphony") {
      this.setState({
        natureSymphonyActive: false,
      });
    } else if (e.currentTarget.id == "close-songs-of-nature") {
      this.setState({
        songsOfNatureActive: false,
      });
    } else if (e.currentTarget.id == "close-disclaimer") {
      alert("Please select your favorite")
      this.setState({
        disclaimerActive: false,
      });
    }
    this.setState({
      displayElements:true
    })
  }
  render() {
    return (
      <Container id="parent">
        <Container id="pg-six">
          <Container id="overlay" style={{opacity:this.state.displayElements?1:0}}>
              <Row id="landing-menu-items" className="landing-menu">
                  <Row>
                    <Button className="menu-option" id="natures-symphony-but" onClick={this.handleButtonClick}>
                      Nature’s Symphony
                    </Button>
                    <Button className="menu-option" id="legend-lore-but" onClick={this.handleButtonClick}>
                      In Lore and Legends
                    </Button>
                    <Button className="menu-option" id="birds-in-music-but" onClick={this.handleButtonClick}>
                      Birds in Music
                    </Button>
                    <Button className="menu-option" id="songs-of-nature-but" onClick={this.handleButtonClick}>
                      Songs of Nature
                    </Button>
                    <br />
                  </Row>
                  <Row>
                    <Button id="disclaimer-button" onClick={this.handleButtonClick}>Disclaimer</Button>
                  </Row>
                </Row>
          </Container>
          <Navbar style={{opacity:this.state.displayElements?1:0}}>
            {/* <NavbarBrand href="/">
              <img
                src={nLogo}
                width={93}
                height={80}
                alt="birdsong-logo"
                id="named-logo"
              />
            </NavbarBrand> */}
            <Customnavbar imglogo={nLogo}/>
            <Nav style={{zIndex:1}} className="ml-auto" navbar>
              <NavItem className="landing-nav-item" id="aboutus">
                <NavLink className="landing-nav-link" href="/aboutus">
                  About Us
                </NavLink>
              </NavItem>
              <NavItem className="landing-nav-item">
                <NavLink
                  className="landing-nav-link"
                  href="https://instagram.com/indianmusicexperience?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <AiFillInstagram size={25} />
                </NavLink>
              </NavItem>
              <NavItem className="landing-nav-item">
                <NavLink
                  className="landing-nav-link"
                  href="mailto:info@indianmusicexperience.org"
                  target="_blank"
                >
                  <IoMail size={25} />
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
          <NaturesSymphonyDesc
            isActive={this.state.natureSymphonyActive}
            handleClose={this.handleClose}
          />
          <LegendLoreDesc
            isActive={this.state.legendLoreActive}
            handleClose={this.handleClose}
          />
          <BirdsInMusicDesc
            isActive={this.state.birdsInMusicActive}
            handleClose={this.handleClose}
          />
          <SongsOfNatureDesc
            isActive={this.state.songsOfNatureActive}
            handleClose={this.handleClose}
          />
          <Disclaimer
            isActive={this.state.disclaimerActive}
            handleClose={this.handleClose}
          />
        </Container>
      </Container>
    );
  }
}

export default Menu;
