import React from "react";
import "../../css/landing-page/PageFour.css";
import { Container, Row, Button } from "reactstrap";

function PageFourPartOne() {
  return (
      <Container id="pg-four">
          <Row id="content-four-1" className = "none">
            <p>
              Birdsong explores the connection between music and the
              environment, through nature's most talented singers- the
              songbirds.
            </p>
          </Row>

          {/* <Row id="content-four-2" className="slide-init">
            <p>
              The exhibition celebrates the biological wonder of the songbird,
              it's connection with humans over time, and a celebration of the
              culture, science, music and mythology it inspires.
            </p>
          </Row> */}
        <a href="/menu">
          <Button className="skip">skip intro</Button>
        </a>
      </Container>
  );
}
export default PageFourPartOne;
