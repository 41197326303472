import React from 'react';
import "../../../css/natures-symphony/AppleRadio.css";
import Courting from '../../../assets/images/natures-symphony/Courting.png';
import appleradio from '../../../assets/images/natures-symphony/appleradio.png';

const AppleRadio = () => {
   return (
      <div className="mainAppleRadio"
      // style={{
      //    display: 'flex',
      //    position: 'relative',
      //    width: '73.64vw',
      //    overflow: 'hidden',
      //    zIndex: '-1',
      //    height: '100vh',
      //    backgroundColor: '#333333'
      // }}
      >
         {/* ====================desktop show ================*/}
         <div className="hideshowhorizontal">
            <img className='courting1' src={Courting} alt='/' />
            <h1 className='count' >01</h1>
            <p className='para'>Singing is one of the most common ways birds proclaim their ownership of their territory. Fierce mother birds use their singing abilities to defend their brood from predators.</p>
            <h1 className='count' id='c2'>02</h1>
            <p className='para' id='p2'> Birds sing to attract and impress potential mates. In certain species, both males and females engage in elaborate duets during courtship. The quality and range of a bird's song are indicators of its level of fitness.
            </p>
            <img className='appleradioimg' src={appleradio} alt='/' />
         </div>


         {/*============== ipad and mobile show ============= */}
         <div className="hideshowhorizontalmoble">
            {/* <img className='courting1' src={Courting} alt='/' /> */}
            <h1 className='count' id='c1' >02</h1>
            <p className='para' id='p1' > Birds sing to attract and impress potential mates. In certain species, both males and females engage in elaborate duets during courtship. The quality and range of a bird's song are indicators of its level of fitness.</p>
            <h1 className='count' id='c2'>03</h1>
            <p className='para' id='p2'>Birds also communicate with each other through their songs and calls much like how we bond with friends over a chat.
            </p>
            <img className='appleradioimg' src={appleradio} alt='/' />
         </div>
      </div>
   );
}

export default AppleRadio;
