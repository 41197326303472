import React from 'react';
import BirdIcon from '../../../assets/images/natures-symphony/BirdIcon.png';
import MenuIcon from '../../../assets/images/natures-symphony/Menu.png';
import {Button} from "reactstrap";
import { Link } from 'react-router-dom';
import SectionMenu from '../../navigation/SectionMenu';
import Customnavbar from '../../Customnavbar/Customnavbar';

class SideBar extends React.Component{
    constructor(props){
        super();
        this.state = {
            menuOpen: false
        }
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }
    openMenu(){
        this.setState({
            menuOpen:true
        })
      }
      closeMenu(){
          this.setState({
              menuOpen:false
          })
    }
    render(){
        return( 
            <div>
                {/* <div className="sideBar">
                    <Link to={ "/menu" }>
                        <img  id="birdIcon"  src={BirdIcon}  alt="/" />
                    </Link>
                    
                    <Button className="nature-symphony-button" id="menu-toggler" onClick={this.openMenu}>
                        <img src={MenuIcon} id="menuIconfirst" alt="menuIcon" />
                    </Button>
                </div> */}
                <Customnavbar imglogo={BirdIcon}  menuicon={MenuIcon}/>
                <SectionMenu
                        isActive = {this.state.menuOpen}
                        handleClose = {this.closeMenu}
                />
            </div>

        )
    }
}


export default SideBar;
