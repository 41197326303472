import React from "react";
import "../../css/landing-page/Disclaimer.css";
import uLogo from "../../assets/images/landing-page/unnamed-logo-black.png";
import { Container } from "reactstrap";
import { VscChromeClose } from "react-icons/vsc";

function Disclaimer(props) {
  return (
    <Container 
      id="disclaimer"
      style={{ display: props.isActive ? "flex" : "none" }}
    >
      <img
        src={uLogo}
        width={93}
        height={80}
        alt="birdsong-logo"
        className="unnamed-logo"
      />
      <h3 className="section-heading" id="disclaimer-heading">
        Disclaimer
      </h3>
      <p className="section-description" id="disclaimer-content">
        This website has been developed for non-profit educational purposes.
        While best efforts have been made to seek permission for use of select
        pieces of media from the original owners, the museum has a takedown
        policy in case of copyright claims.
        <br />
        In case you are the owner of a media piece and would like for it to be
        removed or modified, please reach out to info@indianmusicexperience.org{" "}
        <br />
        All content on this site including media, text, icons, logos and
        software is either an original product of the IME or its content
        suppliers and is protected by copyright laws. We shall not disclose your
        Personal Data to third parties unless you have consented to this
        disclosure. Any personal information provided in the Feedback form will
        remain private, and strictly used for the betterment of the Exhibition.
        <br />
        Please feel free to reach out to us in case of any doubts regarding our
        privacy and copyright policies.
      </p>
      <VscChromeClose
        size={30}
        color={"black"}
        className="close-button"
        id="close-disclaimer"
        onClick={props.handleClose}
      />
    </Container>
  );
}

export default Disclaimer;
