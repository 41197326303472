import React from "react";
import { useParams, Link } from "react-router-dom";
import { Container } from "reactstrap";
import "../../css/songs-of-nature/Birds.css";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { items } from "./sonBirdData";
import bino from "../../assets/images/songs-of-nature/binoculars-image.png";
import { HashLink } from "react-router-hash-link";
import AudioPlayer from "../../utils/AudioPlayer";

function Birds() {
  const { birdId } = useParams();
  let prev = parseInt(birdId) - 1;
  let next = parseInt(birdId) + 1;
  let idx = prev;

  return (
    <Container
      key={items[idx].id}
      id="bird-image"
      style={{ backgroundImage: items[idx].img }}
    >
      <HashLink smooth to={"/son/#son-parent-two"}>
        <AiOutlineClose size={30} id="son-close-button" />
      </HashLink>
      <img src={bino} alt="" id="bino-image" width={65} height={55} />
      <p id="son-name">{items[idx].name}</p>
      <p id="son-desc">{items[idx].desc}</p>
      <AudioPlayer
        url={items[idx].audio}
        size={23}
        className={"son-audio"}
        buttonText={"Birdsong"}
      />
      <br />

      <p id="son-snapper">{items[idx].snapper}</p>
      <div id="son-navigation">
        <Link to={`/birds/${prev === 0 ? 10 : prev}`}>
          <BsArrowLeft color="#CE863F" size={32} />
        </Link>
        <span id="son-id">
          &nbsp;&nbsp;&nbsp;{items[idx].id}&nbsp;&nbsp;&nbsp;
        </span>
        <Link to={`/birds/${next === 11 ? 1 : next}`}>
          <BsArrowRight color="#CE863F" size={32} />
        </Link>
      </div>
    </Container>
  );
}

export default Birds;
