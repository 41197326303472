import React from 'react'
import '../../../css/natures-symphony/TutorGreyMobile.css';
import twobird from '../../../assets/images/natures-symphony/twobird.png'; 

const TutorGreyMobile = () => {
    return (
        <div className="mainTutorGreyMobile">
            <h1 className="mainTutorGreyMobileHeading">Are songbirds born singers or do they have tutors?</h1>
            <div className='mainTutorGreyMobilefirstpara' >
                <p className='mainTutorGreyMobilefirstpara'>Many songbirds are born with patchy vocalisation. Their music education usually begins in the nest. Fledglings pay attention to their parents and other adult songbirds to learn birdsongs, mimicking them to memorise tunes.</p>
            </div>
            <img className='smallBirdtwobird' src={twobird} alt='/' />

        </div>
    )
}

export default TutorGreyMobile