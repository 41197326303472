import React from "react";
import "../../css/landing-page/PageThree.css";
import { Button, Container } from "reactstrap";

function PageThree() {
  return (
    <Container id="pg-three">
      <div id="content-three">
        <p>Birds have inspired music, literature and even modern science. </p>
      </div>
      <a href="/menu">
        <Button className="skip">skip intro</Button>
      </a>
    </Container>
  );
}

export default PageThree;
