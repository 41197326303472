import React from 'react';
import '../../../css/natures-symphony/OrangeNest.css';
import nesteggs from '../../../assets/images/natures-symphony/nesteggs.png';


const OrangeNest=()=>
{
return (
   <div className="mainOrangeNest"
   
//    style={{display:'flex',
//    width: '82.91vw',
//  position: 'relative',
//  overflow: 'hidden',
//  zIndex: '-1',
//   height:'100vh',
// backgroundColor:'#D56D54' }}
>
<p className='orangenestpara'>Songbirds also sing for pleasure. These vocal masters love to display their talents, livening our environment with their melodious chorus. 
</p>
   
<img className='nesteggs' src={nesteggs} alt='/' />
   </div>
);
}

export default OrangeNest;
