import React from "react";

import "../../../css/natures-symphony/Syrinx.css";
import Courting from "../../../assets/images/natures-symphony/Courting.png";

const SyrinxSkin1 = () => {
    return (
        <div
            className="mainSyrinxSkin1"
            // style={{
            //     display: "flex",
            //     // width: "175vw",
            //     position: "relative",
            //     overflow: "hidden",
            //     // zIndex: "-1",
            //     height: "100vh",
            //     backgroundColor: "#F4EACE",
            // }}
        >
            <div
                // style={{ position: "relative", top: "25%", }}
            >
                <h1 className='count'>01</h1>
                <p className='para'> Singing is one of the most common ways birds proclaim their ownership of their territory. Fierce mother birds use their singing abilities to defend their brood from predators.</p>
                <img className="courtingnew" src={Courting} alt="/" />
                <div className="syrinxHeading1" id="syrinxHeading2new">
                    Why do songbirds sing?
                </div>
            </div>
        </div>
    );
};

export default SyrinxSkin1;
