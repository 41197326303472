import React from "react";
import "../../css/landing-page/PageFive.css";
import identity from "../../assets/images/landing-page/identity.png";
import { Container, Row, Button } from "reactstrap";

function PageFive() {
  return (
    <Container id="pg-five">
      <Container className="overlay" id="identity-overlay">
          <Row>
            <img
              src={identity}
              width={250}
              height={300}
              id="identity"
              alt="birdsong-logo"
            />
          </Row>
        <a href="/menu">
          <Button className="skip">skip intro</Button>
        </a>
      </Container>
    </Container>
  );
}

export default PageFive;
