import React from 'react';
import '../../../css/natures-symphony/Tutor.css';
import '../../../css/natures-symphony/Syrinx.css';
import smallbirdlayer from "../../../assets/images/natures-symphony/smallbirdlayer.png"
import bigbirdlayer from '../../../assets/images/natures-symphony/bigbirdlayer.png';
import bigbird from '../../../assets/images/natures-symphony/bigbird.png';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import strokeblue from '../../../assets/images/natures-symphony/strokeblue.png';
import strokeblue2 from '../../../assets/images/natures-symphony/strokeblue2.png';
import Factoidlotus from '../../../assets/images/natures-symphony/Factoidlotus.png';
import FactoidDrawnChorus from '../../../assets/images/natures-symphony/FactoidDrawnChorus.png';
import VideoPopup from './VideoPopup';

const TutorSkin = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="mainTutorSkin"

    //    style={{display:'flex',
    //    width: '100.1vw',
    //  position: 'relative',
    //  overflow: 'hidden',
    //  zIndex: '-1',
    //   height:'100vh',
    // backgroundColor:'#F4EACE' }}
    >


      <img className='smallBirdLayer descohideshowimg' id='skinSmallBirdLayer' src={smallbirdlayer} alt='/' />

      <img className='bigBirdLayer descohideshowimg' src={bigbirdlayer} alt='/' />
      <img className='bigBird descohideshowimg' src={bigbird} alt='/' />
      <p className='TutorPara' >Many songbirds are born with patchy vocalisation. Their music education usually begins in the nest. Fledglings pay attention to their parents and other adult songbirds to learn birdsongs, mimicking them to memorise tunes.</p>

      
      <p className='TutorPara' id='TutorPara2'>By rehearsing songs, they eventually master pitch, rhythm and structure. While nestlings may sound generic, adult songbirds have a refined and extensive repertoire. Adult songbirds like the Oriental Magpie-Robin can sing more than 80 different song types.
      </p>
      <button className='videoButton' id='tutorVideoButton' onClick={handleOpen}> <a><img className='videoIcon' src={videoButton} alt='/' /></a>Watch a video</button>
      <VideoPopup
        vurl="https://www.youtube.com/embed/H0152b17tzw"

        close={handleClose}
        vopen={open}
      />
      <img className='strokeBlue descohideshowimg' id='strokeBlueTutor' src={strokeblue} alt='/' />
      <img className="strokeBlue mobilehideshowimg" id="strokeBlueTutor22" src={strokeblue2} alt="/" />

      <img className='factoidLotus' id='factoidLotusTutor' src={Factoidlotus} alt='/' />
      {/* ==================== desktop screen ==================================== */}
      <img className='factoidHeadingTutor desktopDrawn' src={FactoidDrawnChorus} alt='/' />

      {/* ==================== moblie and ipad screen =========================== */}
      < div className="mobileipadDrawn">
        <h1>Factoid:</h1>
        <h2>Drawn Chorus</h2>
      </div>
      <p className='syrinxPara' id='factoidParaTutor'>It is not only great <i>vidwans</i> and <i>ustads</i> who advocate morning <i>riyaaz</i>, birds do too! Birds all over the world sing the most at sunrise. The marvellous show they put out at this time of day is called the dawn chorus. </p>
    </div>
  );
}

export default TutorSkin;
