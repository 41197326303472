import React from 'react'
import "../../../../css/natures-symphony/game/Instruct.scss"
function Instructions() {
    
    return (
        <div>
            <p className='htpHeading'>How To Play</p>
            <div className='content'>
            <p className='htpsubHeading' id='objective'>Objective: Match pairs of birds which have the same habitat</p>
            <p className='htpsubHeading'>1.Pick any two cards and try to match the habitat of the birds.</p>
            <p className='htpsubHeading'>2.If the cards match, you will learn a fun fact about the bird. </p>
            <p className='htpsubHeading'>3.If the cards don’t match, they go back into the game and you get to try again.</p>
            <p className='htpsubHeading'>4.Keep matching the cards until there are no cards left.</p>

            </div>
            
          </div>
      );
}

export default Instructions