import { useEffect, useState, useRef } from 'react';
import React from 'react';
import Card from "./Card";
import classnames from "classnames";
import '../../../../css/natures-symphony/game/GameContainer.scss'
import { Modal,Box } from '@material-ui/core';
import ModalComponent from './ModalComponent';
import FunFacts from './FunFacts';
import FunFactsContent from './FunFactsContent';
import { useNavigate } from 'react-router-dom';
import {Howl} from 'howler';
import gameNegative from '../../../../audio/gameNegative.mp3';
import gamePositive from '../../../../audio/gamePositive.mp3';
import gameWin from '../../../../audio/gameWin.wav';

let uniqueCardsArray;

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

function GameContainer({playSound}) {

  //audio for wrong matches
  var falseSound = new Howl({
    src: [gameNegative]
  });

  const playOnFalse = () => {
    console.log("playing")
    falseSound.play()
  }
  //audio for game completion
  var winSound = new Howl({
    src: [gameWin]
  });

  const playOnWin = () => {
    console.log("playing")
    winSound.play()
  }
  //audio for right matches
  var trueSound = new Howl({
    src: [gamePositive]
  });

  const playOnTrue = () => {
    console.log("playing")
    trueSound.play()
  }

  
  
    uniqueCardsArray = [
      
      {
        id: 1,
        type: "Wetland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird1.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat01.jpg`),
        funFact:"I can only fly short distances"

      },
      {
        id: 2,
        type: "Forest",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird2.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat02.jpg`),
        funFact:"I have two toes pointing forward and two backward"
      },
      {
        id: 3,
        type: "Grassland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird3.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat03.jpg`),
        funFact:"I am the state bird of Karnataka"
      },
      {
        id: 4,
        type: "Woodland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird4.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat04.jpg`),
        funFact:"I build my nest by excavating a tree hollow"
      },
      {
        id: 5,
        type: "Wetland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird5.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat05.jpg`),
        funFact:"My legs change colour in the breeding season"
      },
      {
        id: 6,
        type: "Wetland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird6.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat06.jpg`),
        funFact:"I spend my entire life in and around water"
      },
      {
        id: 7,
        type: "Wetland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird7.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat07.jpg`),
        funFact:"I am known as Water Crow as I catch fish underwater"
      },
      {
        id: 8,
        type: "Woodland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird8.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat08.jpg`),
        funFact:"I sew leaves together to make my nest"
      },
      {
        id: 9,
        type: "Forest",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird9.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat09.jpg`),
        funFact:"When fully grown, my train has more than 100 feathers"
      },
      {
        id: 10,
        type: "Grassland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird10.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat10.jpg`),
        funFact:"I line my nest with animal hair"
      },
      {
        id: 11,
        type: "Grassland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird11.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat11.jpg`),
        funFact:"My nest is a cup made from mud pellets"
      },
      {
        id: 12,
        type: "Woodland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird12.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat12.jpg`),
        funFact:"I like to live in groups with my own species"
      },
      {
        id: 13,
        type: "Grassland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird13.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat13.jpg`),
        funFact:"I lay eggs on the ground"
      },
      {
        id: 14,
        type: "Woodland",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird14.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat14.jpg`),
        funFact:"I pollinate flowers by probing for nectar"
      },
      {
        id: 15,
        type: "Urban",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird15.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat15.jpg`),
        funFact:"I have lived alongside humans since the Stone Age"
      },
      {
        id: 16,
        type: "Urban",
        frontImage: require(`../../../../assets/images/natures-symphony/game/desktop/bird16.jpg`),
        backImage: require(`../../../../assets/images/natures-symphony/game/desktop/habitat16.jpg`),
        funFact:"I lay eggs in a host nest of another species, which contains an egg or two"
      }

    ];
  
 
  
  const [cards, setCards] = useState(() =>
    shuffleCards(uniqueCardsArray)
  );
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState([]);
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showFact, setShowFact] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [showFirst, setShowFirst] = useState(1);
  const [showSecond, setShowSecond] = useState(1);
  const [elapsedTime, setElapsedTime] = useState('');
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const navigate = useNavigate();
  const timeout = useRef(null);
  const closeFact = ()=>{
    setShowFact(false);
    if(Object.keys(clearedCards).length === uniqueCardsArray.length){
      if (playSound) {
        playOnWin();
      }
      navigate('/exit')
    }
  }
 
  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {

    if (Object.keys(clearedCards).length === uniqueCardsArray.length) {
      
      setShowModal(true);
      setTimeout(() => {  setShowFact(true) }, 5000);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
      console.log(elapsedTime);
    
    }
  };

  const evaluate = () => {
    const [first, second] = openCards;
 
    setShowFirst(first);
    setShowSecond(second);
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].id]:true, [cards[second].id]:true}));
      setOpenCards([]);
      setShowModal(true);
      setShowMatch(true);
      if (playSound) {
        playOnTrue();
      }
      
      setTimeout(() => {  setShowModal(false) }, 8000);
      setTimeout(() => {  setShowMatch(false) }, 8000);
      setTimeout(() => {  setShowFact(true) }, 8000);
      
      return;
    }else{
      setShowModal(true);
      setShowMatch(false);
      if (playSound) {
        playOnFalse();
      }
      setTimeout(() => {  setShowModal(false) }, 8000);
      setTimeout(() => {  setShowMatch(false) }, 8000);
    }
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.id]);
  };

  
  return (
    <div className="AppContainer">
    <div className={classnames("container", {
        "is-open": showModal,
        "show-fact":showFact
      })}>
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              card={card}
              index={index}
              isDisabled={shouldDisableAllCards}
              isInactive={checkIsInactive(card)}
              isFlipped={checkIsFlipped(index)}
              onClick={handleCardClick}
            />
          );
        })}
      </div>
      <div className="popup" >
      <Modal 
        open={showModal&&showMatch}
        BackdropProps={{ invisible: true }}
        disableAutoFocus={true}
        style = {{
          position: 'absolute',
          width: '174px',
          height: '54px',
          marginLeft: '40.20%',
          marginTop: '17%',
          outline: 'none',
          backgroundColor: '#9DDD5E',
          borderRadius: '10px'
          
        }}
      >
          <p className='match-pop'>It's A Match</p>
      </Modal>
      <Modal 
        open={showModal&&(!showMatch)}
        BackdropProps={{ invisible: true }}
        disableAutoFocus={true}
        style = {{
          position: 'absolute',
          width: '174px',
          height: '54px',
          marginLeft: '40.20%',
          marginTop: '17%',
          outline: 'none',
          backgroundColor: '#ED3B3B',
          borderRadius: '10px'
        }}
      >
          <p className='match-pop'>It's not a Match</p>
          
      </Modal>
      <Modal
        open={showModal}
        BackdropProps={{ invisible: true }}
        style = {{
          position: 'absolute',
          width: '160px',
          height: '145px',
          marginLeft: '34.26%',
          marginTop: '23%',
          padding: 0,
          borderRadius: '10px'
        }}
      >
       <ModalComponent card={cards[showFirst]} />
      
      </Modal>
      <Modal
        open={showModal}
        BackdropProps={{ invisible: true }}
        style = {{
          position: 'absolute',
          width: '160px',
          height: '145px',
          left: '189px',
          marginLeft: '34.60%',
          marginTop: '23%',
          padding: 0,
          borderRadius: '10px'
        }}
        className = "modal-modal"
      > 
        <ModalComponent card={cards[showSecond]} />
      
      </Modal>
      </div>
      <div>
      <Modal 
        open={showFact}
        BackdropProps={{ invisible: true }}
        disableAutoFocus={true}
        style = {{
          position: 'absolute',
          width: '17%',
          height: '54px',
          marginLeft: '37.20%',
          marginTop: '11.50%',
          outline: 'none',
          border: 'none',
          backgroundColor: '#87CDDC',
          borderRadius: '10px'
          
        }}
      >
          <FunFacts />
      </Modal>
      <Modal 
        open={showFact}
        BackdropProps={{ invisible: true }}

        style = {{
          position : 'absolute',
          width : '33.9%',
          height : '32.4%',
          marginLeft: '28.3%',
          marginTop: '17.1%',
          opacity: '0.92',
          backgroundColor: 'rgb(10,10,10)',
          borderRadius : '24px' ,
          
        }}
      >
          <Box>
         <FunFactsContent card1={cards[showFirst]} card2={cards[showSecond]}/>
         <button className='backButton' id='continueButton' onClick={closeFact} ><span className='bButton'>Continue</span></button>
         </Box>
      </Modal>
      </div>

     
      </div>
  );
}

export default GameContainer;