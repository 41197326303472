/* Template for slide with header on the left side and content on the right side */
import React from 'react';
import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";
import SlideTextBackgroundRed from "../../../assets/images/birds-in-music/slide-text-background-red.png";
import SlideTextBackgroundBlue from "../../../assets/images/birds-in-music/slide-text-background-blue.png";
import SlideTextBackgroundGreen from "../../../assets/images/birds-in-music/slide-text-background-green.png";
import SlideTextBackgroundWhite from "../../../assets/images/birds-in-music/slide-text-background-white.png";
import SlideTextBackgroundPurple from "../../../assets/images/birds-in-music/slide-text-background-purple.png";
import AudioPlayer from "../utils/AudioPlayer";
import VideoPlayer from "../utils/VideoPlayer";



class ContentColumn extends React.Component {
    constructor(props){
        super(); 
        this.contentHeader = props.contentHeader;
        this.content = props.content;
        this.contenttwo = props.contenttwo;
        this.contentColor = props.contentColor;
        this.contentFontColor = props.contentFontColor?props.contentFontColor:"white";
        this.contentImage = props.contentImage?props.contentImage:null;
        this.contentAudioSection = props.contentAudioSection;
        this.contentAudioSectiontwo = props.contentAudioSectiontwo;
        this.contentVideoSection = props.contentVideoSection;
        this.requestAudioPlayback = props.requestAudioPlayback;
        this.requestAudioPause = props.requestAudioPause;
        this.itemIndex = props.index;


        this.state = {
            audioConfig:props.audioConfig
        }
        // this.audioPlaybackComponents = props.audioPlaybackComponents;


        this.colorMap = {
            "red":SlideTextBackgroundRed,
            "blue":SlideTextBackgroundBlue,
            "green":SlideTextBackgroundGreen,
            "white":SlideTextBackgroundWhite,
            "purple":SlideTextBackgroundPurple
        }


    }
    componentDidUpdate(prevProps){
        if(this.state.audioConfig != this.props.audioConfig){
            this.setState({
                audioConfig:this.props.audioConfig
            });
        }
    
      }
    

    render(){
        return (
            <div className = {this.contentFontColor=="black"?styles.BirdsInMusicContentColumnBlack:styles.BirdsInMusicContentColumn} styles = {this.contentFontColor=="black"?styles.BirdsInMusicContentColumnBlack:styles.BirdsInMusicContentColumn}>
            <div className = {styles.BirdsInMusicTextBackgroundSmall} styles = {styles.BirdsInMusicTextBackgroundSmall}>
                    <img src={this.colorMap[this.contentColor]} className={(this.contentColor == "white"  || this.contentColor == "red"  || this.contentColor == "blue" && this.contentImage.temp == "11") ? this.contentColor == "red" ? "redclasswidth ": "Peacockclass" : ""} style = {{height:"100%" }}></img>
            </div>
            <div className = { this.contentColor == "red" ? styles.BirdsInMusicContentWrappertwo : styles.BirdsInMusicContentWrapper} styles = {styles.BirdsInMusicContentWrapper}>
                {/* {console.log(this.contentColor == "red")}    */}
                <div className = {styles.BirdsInMusicContentHeader} styles = {styles.BirdsInMusicContentHeader}>
                    <h3 style={{fontSize:"1.17em"}} >{this.contentHeader}</h3>
                </div>
                <div className = {styles.BirdsInMusicContent} styles = {styles.BirdsInMusicContent}>
                    <p>{this.content} </p>
                    <p>{this.contenttwo} </p>
                    
                </div>
                {
                    this.contentAudioSection?
                        <div className = "birds-in-music-audio-section" style = {{marginTop:this.contentAudioSection.margin?this.contentAudioSection.margin:"5%",zIndex:100}}>
                            <AudioPlayer 
                                url = {this.contentAudioSection.audioUrl} 
                                requestAudioPlayback = {this.requestAudioPlayback} 
                                requestAudioPause = {this.requestAudioPause}
                                audioConfig = {this.state.audioConfig}
                                itemIndex = {this.itemIndex}
                            >
                            </AudioPlayer>


                            <div className = {styles.BirdsInMusicAudioDescription} styles = {styles.BirdsInMusicAudioDescription}>
                                <p>{this.contentAudioSection.description} </p>
                            </div>
                
                            <div className = {styles.BirdsInMusicAudioCredits}>
                                <p>
                                    {this.contentAudioSection.credits}
                                </p>
                            </div>
                        </div>
                        
                    :null
                
                }
                     {
                    this.contentAudioSectiontwo?
                        <div className = "birds-in-music-audio-section" style = {{marginTop:this.contentAudioSectiontwo.margin?this.contentAudioSectiontwo.margin:"5%",zIndex:100}}>
                            <AudioPlayer 
                                url = {this.contentAudioSectiontwo.audioUrltwo} 
                                requestAudioPlayback = {this.requestAudioPlayback} 
                                requestAudioPause = {this.requestAudioPause}
                                audioConfig = {this.state.audioConfig}
                                itemIndex = {this.itemIndex}
                            >
                            </AudioPlayer>


                            <div className = {styles.BirdsInMusicAudioDescription} styles = {styles.BirdsInMusicAudioDescription}>
                                <p>{this.contentAudioSectiontwo.description} </p>
                            </div>
                
                            <div className = {styles.BirdsInMusicAudioCredits}>
                                <p>
                                    {this.contentAudioSectiontwo.credits}
                                </p>
                            </div>
                        </div>
                        
                    :null
                
                }
                
                {
                    this.contentVideoSection?
                    <div className = "birds-in-music-video-section" style = {{marginTop:this.contentVideoSection.margin?this.contentVideoSection.margin:"10%",zIndex:100}}>
                        <VideoPlayer url = {this.contentVideoSection.videoUrl}></VideoPlayer>
                        <div className = {styles.BirdsInMusicVideoDescription} styles = {styles.BirdsInMusicVideoDescription}>
                            <p>{this.contentVideoSection.description} </p>
                        </div>
            
                        <div className = {styles.BirdsInMusicVideoCredits}>
                            <p>
                                {this.contentVideoSection.credits}
                            </p>
                        </div>
                    </div>
                    
                :null
                }





            </div>

            {
                this.contentImage?
                <div 
                    className = "BirdsInMusicSlideContentImg" 
                    style = {{
                        width: this.contentImage.width?this.contentImage.width:"40vh", 
                        position:"absolute",
                        bottom:this.contentImage.bottom?this.contentImage.bottom:"15%",
                        left:this.contentImage.left?this.contentImage.left: "28%"
                    }}


                >
                            <img src={this.contentImage.img} className={(this.contentImage.temp == "11") ? "Peacockclassimg" : "" || (this.contentImage.temp == "12") ? "Bulbulclassimg" : ""  } 
                            style = {{width:"100%"}}></img>
                </div>
                :null

            }

            </div>
        )      
    }
}

export default ContentColumn;