import styled, { keyframes } from "styled-components";
import {
  slideInLeft,
  slideInRight,
  slideInUp,
  slideInDown,
  fadeIn,
} from "react-animations";

const SlideLeft = styled.div`
  animation: 2.5s ${keyframes`${slideInLeft}`};
  animation-delay: -0.5s;
  padding: 0;
`;
const SlideRight = styled.div`
  animation: 2s ${keyframes`${slideInRight}`};
  width: auto;
  padding: 0;
`;
const SlideUp = styled.div`
  animation: 2s ${keyframes`${slideInUp} from {
    transform: translateY(300%);
  }
  to {
    transform: translateY(0%);
  }`};
  padding: 0;
`;

const ColorChange = styled.div`
  animation: 2s ${keyframes`
0% { color:rgba(0, 0, 0, 1); }
50% {color: rgba(0, 0, 0, 0.5);}
 100% { color: #fff; }`};
  animation-fill-mode: forwards;
`;
const SlideDown = styled.div`
  animation: 2s ${keyframes`${slideInDown}`};
  padding: 0;
`;

const FadeIn = styled.div`
  animation: 2s ${keyframes`${fadeIn} `};
`;

const ButtonFadeIn = styled.div`
  animation: 2s ${keyframes`${fadeIn} `};
  display: flex;
  flex-grow: 1;
`;

export {
  SlideLeft,
  SlideRight,
  SlideUp,
  SlideDown,
  FadeIn,
  ColorChange,
  ButtonFadeIn,
};
