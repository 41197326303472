import React from 'react'
import '../../../../css/natures-symphony/game/Game.scss';
import '../../../../css/natures-symphony/game/Exit.scss';
import { useNavigate } from 'react-router-dom';

function ExitScreen() {
  let home;

  const navigate = useNavigate();
  const start=()=>{
    navigate('/gameLand');
  }
 
  const quit=()=>{
    navigate('/naturesymphony');
  }
 
  home = require(`../../../../assets/images/natures-symphony/game/desktop/homeIcon.jpg`)


  return (
    <div className='containerOuter'>
        <div className='containerInner'>
            <h1 className='header1'>bird</h1>
            <h1 className='header2'>house</h1>
            <div className='homeIcon'><button className='homeIconButton'><img src={home} width="66px" height="56px" alt="pokeball" onClick={start} /></button></div>
           <p className='exitScreenPara'>Thanks for playing !</p>
           <div className='replay'><button className='replayButton' onClick={start}>Replay</button></div>
           <div className='quit'><button className='quitButton' onClick={quit}>Quit</button></div>
        </div>
       
    </div>
  )
}

export default ExitScreen