import React from "react";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container } from "reactstrap";
import "../../css/navigation/section-menu.css";

import BirdIcon from "../../assets/images/natures-symphony/BirdIcon.png";
import Arrow from "../../assets/images/natures-symphony/Arrow.png"
import Dot from "../../assets/images/natures-symphony/Dot.png"

class SectionMenu extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        return (
            <Container id="section-menu" style={{ display: this.props.isActive ? "block" : "none" }}>

                {/* ==============Desktop ========================= */}
                <Container id="section-menu-container" className="desktopshowsetionmenu">
                    <Row className="section-menu-row">
                        <Link to={"/naturesymphony"}>
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Nature’s Symphony
                            </Button>
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/lore"}>
                            <Button className="section-menu-option" id="natures-symphony-but">
                                In Lore and Legends
                            </Button>
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/birdsinmusic"}>
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Birds In Music
                            </Button>
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/son"}>
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Songs of Nature
                            </Button>
                        </Link>
                    </Row>

                </Container>

                {/* ============= Mobile and Ipad================ */}
                <Container id="section-menu-container" className="MobileandIpadshowsetionmenu">
                    <Row className="section-menu-row-first">
                        <img src={BirdIcon} className="section-BirdIcon"  />
                    </Row>
                    <Row className="section-menu-row-first">
                        <h1 style={{ color: "black" }} className="section-hading-Menu"><b> Menu </b></h1>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/naturesymphony"} className="section-menu-link">
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Nature’s Symphony
                            </Button>
                            <img src={Arrow} className="section-menu-img" />
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/lore"} className="section-menu-link" >
                            <Button className="section-menu-option" id="natures-symphony-but">
                               In Lore and Legends
                            </Button>
                            <img src={Arrow} className="section-menu-img" />
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/birdsinmusic"} className="section-menu-link" >
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Birds In Music
                            </Button>
                            <img src={Arrow} className="section-menu-img" />
                        </Link>
                    </Row>
                    <Row className="section-menu-row">
                        <Link to={"/son"} className="section-menu-link" >
                            <Button className="section-menu-option" id="natures-symphony-but">
                                Songs of Nature
                            </Button>
                            <img src={Arrow} className="section-menu-img" />
                        </Link>
                    </Row>
                    <Row className="section-disclaimer">
                        <Link to="/disclaimerMobile">
                            <Button className="section-menu-option section-fontdisclaimer" id="natures-symphony-but">
                                <small>disclaimer</small>
                            </Button>
                        </Link>
                        <img src={Dot} />
                        <Link to="/aboutus">
                            <Button className="section-menu-option section-fontdisclaimer" id="natures-symphony-but">
                                <small>About Us</small> 
                            </Button>
                        </Link>
                    </Row>
                </Container>


                <VscChromeClose
                    size={30}
                    color={"black"}
                    className="close-button"
                    id="close-birds-in-music"
                    onClick={this.props.handleClose}
                />


            </Container>
        )

    }
}

export default SectionMenu;