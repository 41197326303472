import { v4 as uuid } from "uuid";
var birdOne = require("../../assets/images/songs-of-nature/rusty-bellied-shortwing.jpg");
var birdTwo = require("../../assets/images/songs-of-nature/white-rumped-shama.jpg");
var birdThree = require("../../assets/images/songs-of-nature/malabar-whistling-thrush.jpg");
var birdFour = require("../../assets/images/songs-of-nature/himalyan-thrush.jpg");
var birdFive = require("../../assets/images/songs-of-nature/puff-throated-babbler.jpg");
var birdSix = require("../../assets/images/songs-of-nature/purple-sunbird.jpg");
var birdSeven = require("../../assets/images/songs-of-nature/grey-winged-blackbird.jpg");
var birdEight = require("../../assets/images/songs-of-nature/oriental-magpie-robin.jpg");
var birdNine = require("../../assets/images/songs-of-nature/asian-koel.jpg");
var birdTen = require("../../assets/images/songs-of-nature/red-whiskered-bulbul.jpg");

export const items = [
  {
    uid: uuid(),
    id: 1,
    name: "Rusty-bellied Shortwing",
    desc: "The Rusty-bellied Shortwing has a distinctive, high-pitched call consisting of a series of sharp whistles, often repeated in quick succession.",
    img: "url(" + birdOne + ")",
    snapper: "Eejay Bhutan",
    audio:
      "https://mcdn.podbean.com/mf/web/piebd6/Rusty-bellied_Shortwing_-_Brachypteryx_hyperythra6v8bn.mp3",
  },
  {
    uid: uuid(),
    id: 2,
    name: "White-rumped Shama",
    desc: "The voice of this species is rich and melodious. One of the first recordings of a birdsong that was ever made was of the White-rumped Shama in 1889 using an Edison wax cylinder by Ludwig Koch in Germany. ",
    img: "url(" + birdTwo + ")",
    snapper: "Albin Jacob",
    audio: "https://mcdn.podbean.com/mf/web/dxmpxs/Indian_Shamabhjm4.mp3",
  },
  {
    uid: uuid(),
    id: 3,
    name: "Malabar Whistling-Thrush",
    desc: "The Malabar Whistling-Thrush is known as the whistling schoolboy because of the human-like whistling cries it makes at daybreak.",
    img: "url(" + birdThree + ")",
    snapper: "Gavin Emmons",
    audio:
      "https://mcdn.podbean.com/mf/web/m7cr2b/Malabar_whistling_thrush.mp3",
  },
  {
    uid: uuid(),
    id: 4,
    name: "Himalayan Thrush",
    desc: "The Himalayan thrush has a complex, melodious call of whistles, trills, and chirps. Its impressive vocal range is an essential part of courtship and territorial behavior.",
    img: "url(" + birdFour + ")",
    snapper: "Aparajita Datta",
    audio:
      "https://mcdn.podbean.com/mf/web/a6ypcf/XC301071_-_Himalayan_Thrush_-_Zoothera_salimalii888yn.mp3",
  },
  {
    uid: uuid(),
    id: 5,
    name: "Puff-throated Babbler",
    desc: "Puff-throated Babblers forage in lively flocks on the forest floor and give out clear, rattling, and rasping calls.",
    img: "url(" + birdFive + ")",
    snapper: "Manoj Karingamadathil",
    audio:
      "https://mcdn.podbean.com/mf/web/michea/Pellorneum_ruficeps_-_Puff-throated_Babbler_XC597726.mp3",
  },
  {
    uid: uuid(),
    id: 6,
    name: "Purple sunbird",
    desc: <> Sunbirds are small passerine birds endemic to the Indian subcontinent. During the breeding season, males perform a courtship display by fluttering their wings in front of females while singing.</>,
    img: "url(" + birdSix + ")",
    snapper: "Garima Bhatia",
    audio: "https://mcdn.podbean.com/mf/web/d6nc79/Purple-rumped_Sunbird.mp3",
  },
  {
    uid: uuid(),
    id: 7,
    name: "Grey-winged Blackbird",
    desc: " The Grey-winged Blackbird's sweet and melodious song is generally slow-paced, with a rich and mellow tone. It can be heard throughout the day but is particularly prevalent in the early morning and late afternoon.",
    img: "url(" + birdSeven + ")",
    snapper: "Khoitran1957",
    audio:
      "https://mcdn.podbean.com/mf/web/6t6v7x/Grey-winged_Blackbird_-_Turdus_boulboul76yw5.mp3",
  },
  {
    uid: uuid(),
    id: 8,
    name: "Oriental Magpie-Robin",
    desc: "The Oriental Magpie-Robin is renowned for its large repertoire of melodious songs, which feature a diverse range of notes and phrases. Their vocalizations are known for their complexity and produce an uplifting sound.",
    img: "url(" + birdEight + ")",
    snapper: "Subadra Devi",
    audio:
      "https://mcdn.podbean.com/mf/web/ne794p/Oriental_Magpie-robin_Hareesha_AS9fb73.mp3",
  },
  {
    uid: uuid(),
    id: 9,
    name: "Asian Koel",
    desc: "These shy birds are found throughout the Indian subcontinent. Famous for its loud call, the female cuckoo lays eggs in the nests of crows or drongos.  ",
    img: "url(" + birdNine + ")",
    snapper: "Gururaj Moorching",
    audio:
      "https://mcdn.podbean.com/mf/web/ydyjff/Asian_Koel_-Viral_Joshi9r3dh.mp3",
  },
  {
    uid: uuid(),
    id: 10,
    name: "Red-whiskered Bulbul",
    desc: " The Red-whiskered Bulbul, found in pairs or small groups in gardens, orchards, and open forests, sings a pleasant three-note whistle with a cheery and melodious tone, often repeated in quick succession.",
    img: "url(" + birdTen + ")",
    snapper: "Ramki Srinivasan",
    audio:
      "https://mcdn.podbean.com/mf/web/m7bu9i/Red-whiskered_Bulbul_Samira_Agnihotri8hjvd.mp3",
  },
];
