/* Template for slide with header on the left side and content on the right side */
import React from 'react';
import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";
import SlideTextBackgroundRed from "../../../assets/images/birds-in-music/slide-text-background-red.png";
import SlideTextBackgroundBlue from "../../../assets/images/birds-in-music/slide-text-background-blue.png";
import SlideTextBackgroundGreen from "../../../assets/images/birds-in-music/slide-text-background-green.png";
import SlideTextBackgroundWhite from "../../../assets/images/birds-in-music/slide-text-background-white.png";
import SlideTextBackgroundPurple from "../../../assets/images/birds-in-music/slide-text-background-purple.png";



class HeaderColumn extends React.Component {
    constructor(props){
        super(); 
        this.header = props.header;  
        this.headerColor = props.headerColor; 
        this.headerFontColor = props.headerFontColor?props.headerFontColor:"white";
        this.headerImage = props.headerImage?props.headerImage:null;


        this.colorMap = {
            "red":SlideTextBackgroundRed,
            "blue":SlideTextBackgroundBlue,
            "green":SlideTextBackgroundGreen,
            "white":SlideTextBackgroundWhite,
            "purple":SlideTextBackgroundPurple
        }


    }

    render(){
        return (
                <div className = {this.headerFontColor=="black"?styles.BirdsInMusicHeaderColumnBlack:styles.BirdsInMusicHeaderColumn} styles = {this.headerFontColor=="black"?styles.BirdsInMusicHeaderColumnBlack:styles.BirdsInMusicHeaderColumn}>
                    <div className = {styles.BirdsInMusicTextBackgroundLarge}>
                        <img src ={this.colorMap[this.headerColor]} style = {{height:"100%"}}></img>
                    </div>
                    <div className = {styles.BirdsInMusicSlideHeader} styles = {styles.BirdsInMusicSlideHeader}>
                        <h2>{this.header}</h2>
                    </div>

                    {
                        this.headerImage?
                            <div className = "BirdsInMusicSlideHeaderImg" 
                                style = {{
                                    width: this.headerImage.width?this.headerImage.width:"55vh", 
                                    position:"absolute",
                                    top:this.headerImage.top?this.headerImage.top:"34%",
                                    left:this.headerImage.left?this.headerImage.left: "28%"
                                }}>
                                <img src = {this.headerImage.img} style = {{width:"100%"}}></img>
                            </div>
                        :null
                    }

                </div>
        )
    }
}

export default HeaderColumn;