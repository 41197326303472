import React from 'react';
import logo from "../../assets/images/birds-in-music/birdsong-logo.png";
import menu from "../../assets/images/birds-in-music/birdsong-menu.png";
import BirdsInMusicLandingPage from './slides/BirdsInMusicLandingPage';
import BirdsInMusicSlide2 from './slides/BirdsInMusicSlide2';
import BirdsInMusicSlide from './templates/BirdsInMusicSlide';
import items from './items';
import SectionMenu from '../navigation/SectionMenu';

// import "../../css/birds-in-music/BirdsInMusic.css"
import styles from "../../css/birds-in-music/BirdsInMusic.module.css";

import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import Slide3Img1 from "../../assets/images/birds-in-music/slide3-img1.png";
import BirdsInMusicMobile from './BirdsInMusicMobile';


// import SliderDeskRed from "../../../assets/images/birds-in-music/slide-text-background-red.png";
// import Slide1red2 from "../../assets/images/birds-in-music/slide-text-background-red-mobile2.png";
// import Slide1red from "../../assets/images/birds-in-music/slide-text-background-red-mobile.png";


import BirdsInMusicLandingDesktop from './slides/BirdsInMusicLandingDesktop';
import Customnavbar from '../Customnavbar/Customnavbar';


class BirdsInMusic extends React.Component {
    constructor(props) {
        super();
        this.mouseDown = false;


        this.state = {
            mouseDown: false,
            mouseDownX: false,
            translateX: 0,
            relativeCenter: 0,
            sliderWidth: (Math.ceil(items.length / 2) * 100 + 200) + "vw",
            menuOpen: false,
            audioPlaybackComponents: {},
            currentAudioPlaybackIndex: null,
            currentAudioPlayer: null
        }


        this.handleDrag = this.handleDrag.bind(this);
        this.startDrag = this.startDrag.bind(this);
        this.endDrag = this.endDrag.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.requestAudioPlayback = this.requestAudioPlayback.bind(this);
        this.requestAudioPause = this.requestAudioPause.bind(this);
        this.initializeAudioPlaybackComponents();

    }

    initializeAudioPlaybackComponents() {
        var audioPlaybackComponents = {};
        var slideCount = Math.ceil(items.length / 2);
        for (let i = 0; i < slideCount; i++) {
            var leftPanelIndex = 2 * i;
            var rightPanelIndex = (2 * i) + 1;

            if (leftPanelIndex < items.length && items[leftPanelIndex]['audioSection']) {
                audioPlaybackComponents[leftPanelIndex] = {
                    play: false,
                    pause: true
                }

            }
            if (rightPanelIndex < items.length && items[rightPanelIndex]['audioSection']) {
                audioPlaybackComponents[rightPanelIndex] = {
                    play: false,
                    pause: true
                }
            }


        }
        this.state.audioPlaybackComponents = audioPlaybackComponents;
    }

    requestAudioPlayback(itemIndex, audio) {
        var that = this;
        if (that.state.audioPlaybackComponents[itemIndex]) {
            if (that.state.currentAudioPlaybackIndex) {
                var audioPlaybackComponents = that.state.audioPlaybackComponents;
                audioPlaybackComponents[that.state.currentAudioPlaybackIndex] = { play: false, pause: true };
                audioPlaybackComponents[itemIndex] = { play: true, pause: false }
                that.setState({
                    audioPlaybackComponents: audioPlaybackComponents,
                    currentAudioPlaybackIndex: itemIndex,
                    currentAudioPlayer: audio
                });
            }
            else {
                var audioPlaybackComponents = that.state.audioPlaybackComponents;
                audioPlaybackComponents[itemIndex] = { play: true, pause: false }
                that.setState({
                    audioPlaybackComponents: audioPlaybackComponents,
                    currentAudioPlaybackIndex: itemIndex,
                    currentAudioPlayer: audio
                });
            }
        }

    }
    requestAudioPause(itemIndex) {

        if (this.state.audioPlaybackComponents[itemIndex]) {
            var audioPlaybackComponents = this.state.audioPlaybackComponents;
            audioPlaybackComponents[itemIndex] = { play: false, pause: true };
            this.setState({
                audioPlaybackComponents: audioPlaybackComponents,
                currentAudioPlaybackIndex: null,
                currentAudioPlayer: null
            })
        };

    }

    handleScroll(e) {

        var translateX = e.deltaY + (this.state.relativeCenter);
        if (translateX > 0) {
            this.setState({ translateX: 0, relativeCenter: 0 });
        }
        else if (translateX < -(items.length / 2 + 1) * window.innerWidth) {
            translateX = -(items.length / 2 + 1) * window.innerWidth;
            this.setState({ translateX: translateX, relativeCenter: translateX });
        }
        else if (translateX <= 0 && translateX > -(items.length / 2 + 1) * window.innerWidth) {
            this.setState({ translateX: translateX, relativeCenter: translateX })

        }



    }

    handleDrag(e) {
        if (this.state.mouseDown) {
            var translateX = e.clientX - this.state.mouseDownX + (this.state.relativeCenter);

            if (translateX <= 0 && translateX > -(items.length / 2 + 1) * window.innerWidth) {
                this.setState({ translateX: translateX })
            }
        }

    }

    startDrag(e) {

        this.setState({ mouseDown: true, mouseDownX: e.clientX });
    }

    endDrag(e) {

        var relativeCenter = this.state.translateX
        this.setState({ mouseDown: false, mouseDownX: false, relativeCenter: relativeCenter })
    }
    renderSlides() {
        var slides = [];

        var slideCount = Math.ceil(items.length / 2);
        for (let i = 0; i < slideCount; i++) {
            var leftPanelIndex = 2 * i;
            var rightPanelIndex = (2 * i) + 1;
            var data = {
                leftPanel: leftPanelIndex < items.length ? items[leftPanelIndex] : {},
                rightPanel: rightPanelIndex < items.length ? items[rightPanelIndex] : {}
            }

            data['leftPanel']['index'] = leftPanelIndex;
            data['rightPanel']['index'] = rightPanelIndex;


            slides.push(
                <BirdsInMusicSlide
                    data={data}
                    requestAudioPlayback={this.requestAudioPlayback}
                    requestAudioPause={this.requestAudioPause}
                    audioPlaybackComponents={this.state.audioPlaybackComponents}
                />
            )
        }
        return slides;
    }

    openMenu() {
        this.setState({
            menuOpen: true
        })
    }
    closeMenu() {
        this.setState({
            menuOpen: false
        })
    }

    componentWillUnmount() {
        if (this.state.currentAudioPlayer) {
            this.state.currentAudioPlayer.pause();
        }
    }


    render() {
        return (
            <>
                {/* ============ Desktop =================== */}

                <div className="flex fill center container hidemobile" id="birds-in-music">
                    {/* <div id="birds-in-music-menu">
                        <Link to={"/menu"}>
                            <Button className={styles.BirdsInMusicButton} id="unnamed-logo">
                                <img style={{position:'fixed' , top:"3.5vh", left:"2.5vh"}} src={logo} height={"100"} width={"120"} />
                            </Button>
                        </Link>

                        <Button className={styles.BirdsInMusicButton} id="menu-toggler" onClick={this.openMenu}>
                            <img src={menu}  />
                        </Button>


                    </div> */}
                      <Customnavbar imglogo={logo}  menuicon={menu}/>
                    <div className="birds-in-music-slider" style={{ height: "100vh", width: this.state.sliderWidth, overflow: "hidden" }}>
                        <div className={styles.BirdsInMusicInner}
                            style={{
                                transform: "translateX(" + this.state.translateX + "px)",                   
                                transition: "transform 0.s"
                            }}
                            onMouseDown={this.startDrag}
                            onMouseMove={this.handleDrag}
                            onMouseUp={this.endDrag}
                            onWheel={this.handleScroll}
                        >
                             <BirdsInMusicLandingPage></BirdsInMusicLandingPage>
                            <BirdsInMusicSlide2></BirdsInMusicSlide2>
                           {this.renderSlides()}
                            {/* <BirdsInMusicLandingDesktop /> */}
                        </div>
                    </div>


                    <SectionMenu
                        isActive={this.state.menuOpen}
                        handleClose={this.closeMenu}
                    />


                </div>

                {/* ============Mobile and ipad =================== */}

                <div className="flex fill center container showMobile" id="birds-in-music">
                    {/* <div id="birds-in-music-menu">
                        <Link to={"/menu"}>
                            <Button className={styles.BirdsInMusicButton} id="unnamed-logo">
                                <img src={logo} height={"80"} width={"90"} />
                            </Button>
                        </Link>

                        <Button className={styles.BirdsInMusicButton} id="menu-toggler" onClick={this.openMenu}>
                            <img src={menu} height={"50"} width={"50"} />
                        </Button>
                    </div> */}

                    {/* <div className="sideBar">
                        <Link to={"/menu"}>
                            <img id="birdIcon" src={logo} alt="/" />
                        </Link>

                        <Button
                            className="nature-symphony-button"
                            id="menu-toggler"
                            onClick={this.openMenu}
                        >
                            <img src={menu} id="menuIconfirst" alt="menuIcon" />
                        </Button>
                    </div> */}
                     <Customnavbar imglogo={logo}  menuicon={menu}/>
                    <div className={styles.birdsinmusicslider} style={{ height: "100vh" }}>

                        <BirdsInMusicLandingPage></BirdsInMusicLandingPage>
                        <BirdsInMusicSlide2></BirdsInMusicSlide2>
                        <BirdsInMusicMobile></BirdsInMusicMobile>

                    </div>



                    <SectionMenu
                        isActive={this.state.menuOpen}
                        handleClose={this.closeMenu}
                    />
                </div>
            </>
        );
    }
}

export default BirdsInMusic;


