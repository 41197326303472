import React, { useRef, useState } from 'react'
import "../../css/birds-in-music/BirdsInMusic.css"
import styles from "../../css/birds-in-music/BirdsInMusic.module.css";

import play_arrow from "../../images/play_arrow.png";
import puaseimg from "../../images/puaseimg.png";
import Koyalia_Bole_Drut_Bandish_2 from "../../images/Koyalia_Bole_Drut_Bandish_2.mp3";
import Peacock_3 from "../../images/Peacock_3.mp3";
import Koel_4 from "../../images/Koel_4.mp3";
import Swan_5 from "../../images/Swan_5.mp3";
import Crow30_6 from "../../images/Crow30_6.mp3";
import Geena_Solega_folk_7 from "../../images/Geena_Solega_folk_7.mp3";
import Sua_Geet_8 from "../../images/Sua_Geet_8.mp3";
import Vinatha_Sutha_1 from "../../images/Vinatha_Sutha_1.mp3";


import Slide3Img1 from "../../assets/images/birds-in-music/slide3-img1.png";
import Slide5Img1 from "../../assets/images/birds-in-music/slide5-img1.png";

// import Slide5Img2 from "../../assets/images/birds-in-music/slide5-img2.png";
import Slide5Imgmob from "../../assets/images/birds-in-music/slide5-img2mob.png";


import Slide6Img1 from "../../assets/images/birds-in-music/slide6-img1.png";
import Slide6Img2 from "../../assets/images/birds-in-music/slide6-img2.png";
// import Slide7Img1 from "../../assets/images/birds-in-music/slide7-img1.png";
import Slide7Imgmob2 from "../../assets/images/birds-in-music/slide7-mobileimg2.png";
import Slide7Img2 from "../../assets/images/birds-in-music/slide7-img2.png";
import Slide8Img1 from "../../assets/images/birds-in-music/slide8-img1.png";
import Slide8Img2 from "../../assets/images/birds-in-music/slide8-img2.png";
import Slide12Img1 from "../../assets/images/birds-in-music/slide12-img1.png";
import Slide9Img222 from "../../assets/images/birds-in-music/slide9-img222.png";
import Slide9Img2 from "../../assets/images/birds-in-music/slide9-img2.png";
import Slide10Img1 from "../../assets/images/birds-in-music/slide10-img1.png";
import Slide10Img2 from "../../assets/images/birds-in-music/slide10-img2.png";
import Slide11Img1 from "../../assets/images/birds-in-music/slide11-img1.png";
import Slide11Img2 from "../../assets/images/birds-in-music/slide11-img2.png";






import Slide1red2 from "../../assets/images/birds-in-music/slide-text-background-red-mobile2.png";
import Slide1red from "../../assets/images/birds-in-music/slide-text-background-red-mobile.png";
import Slide2Blue2 from "../../assets/images/birds-in-music/slide-text-background-blue-mobile2.png";
import Slide2Blue from "../../assets/images/birds-in-music/slide-text-background-blue-mobile.png";
import Slide2Green2 from "../../assets/images/birds-in-music/slide-text-background-green-mobile2.png";
import Slide2Green from "../../assets/images/birds-in-music/slide-text-background-green-mobile.png";
import Slide2Purple2 from "../../assets/images/birds-in-music/slide-text-background-purple-mobile2.png";
import Slide2Purple from "../../assets/images/birds-in-music/slide-text-background-purple-mobile.png";
import Slide2White2 from "../../assets/images/birds-in-music/slide-text-background-white-mobile2.png";
import Slide2White from "../../assets/images/birds-in-music/slide-text-background-white-mobile.png";
// import GotoInloreLegends from '../natures-symphony/components/GotoInloreLegends';



import GoToSongsOfNature from '../../assets/images/birds-in-music/GoToSongsOfNature.png';
import { Link } from 'react-router-dom';


const BirdsInMusicMobile = () => {


    const [isPlaying, setIsPlaying] = useState(new Array(8).fill(false));
    const audioRefs = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null)];

    const handlePlayPause = (index) => {
        // console.log("isPlaying===", isPlaying);

        const newIsPlaying = [...isPlaying];

        if (newIsPlaying[index]) {
            audioRefs[index].current.pause();
        }
        else {
            // for (let i = 0; i < 8; i++) {
            //     audioRefs[i].current.pause();
            // }
            audioRefs[index].current.play();

        }
        newIsPlaying[index] = !newIsPlaying[index];
        setIsPlaying(newIsPlaying);
        // console.log("newIsPlaying=========",newIsPlaying);
    };


    const [video, setVideo] = useState("");
    const videoRef = useRef(null);

    const [model, setmodel] = useState(false);

    const ShowModal = (data) => {
        setmodel(true)
        setVideo(data)
    }
    const hideModel = () => {
        setmodel(false)
    }

    return (
        <>

            {/* --------------------------------------------------------------- RED ---------------------------------------------------------------- */}

            <div className="mainOutBox mainBox1" style={{ border: "0px solid red" }} >

                <div className="firstBox"  >
                    <div className="firstInnerBox">
                        {/* <img src={SliderDeskRed} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide1red2} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="firstInnerMainBox">
                            <div className="firstInnerMainBox2">
                                <h2>A flight in Ragas</h2>
                                <img src={Slide3Img1} alt="Image" className="firstInnerMainBox2img" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskRed} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide1red} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2>Birds in Indian Classical Music: An Introduction</h2>
                                <p className="description">Karnatik music compositions refer to birds as mounts (<i>vahanas</i>) of gods and goddesses. Hindustani music compositions are replete with bird symbols and metaphors. From Swans and Parrots to Peacocks and Koels, many compositions in Hindustani classical music have looked to birds for inspiration in narratives of love, seasons, devotion and struggle.
                                </p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[0]} src={Vinatha_Sutha_1} />
                                    <button onClick={() => handlePlayPause(0)}>
                                        <img src={isPlaying[0] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[0] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p>
                                        D. K. Jayaraman sings Vinatha Sutha, holding references to the legendary Garuda, son of Vinata.
                                    </p>
                                    <p className="secndpara">Credits: Mahadeva (1983)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskRed} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide1red} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                {/* <h2>Birds in Indian Classical Music: An Introduction</h2> */}
                                <p className="description">Several ragas in Indian classical music are named after birds. While Karnatik
                                    music has a number of such ragas, Hindustani music has a few, such as <span className='fontitalic'> Hansakinkini</span> and <spna className="fontitalic">Vihang</spna> (literally meaning bird). In most instances, the names
                                    of the ragas do not bear a direct connection to the birds they are named after.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[1]} src={Koyalia_Bole_Drut_Bandish_2} />
                                    <button onClick={() => handlePlayPause(1)}>
                                        <img src={isPlaying[1] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[1] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p >
                                        Pandit Dattatreya Vishnu Paluskar sings Koyaliya Bole, set in Raag Tilak Kamod,
                                        and laid on the structure of teental.
                                    </p>
                                    <p className="secndpara" >Credits: Golden Milestones</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* --------------------------------------------------------------- BLUE ---------------------------------------------------------------- */}

            <div className="mainOutBox mainBox2" >

                <div className="firstBox">
                    <div className="firstInnerBox">
                        <img src={Slide2Blue2} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="firstInnerMainBox">
                            <h2>Metaphors in Melody</h2>
                            <img src={Slide5Img1} alt="Image" className="firstInnerMainBox2img" />
                        </div>
                    </div>
                </div>


                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        <img src={Slide2Blue} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2>Peacock</h2>
                                <p className="description">Peacock imagery has often been associated with rainy weather and monsoons, and used to imply fertility in nature. Hindustani compositions also mention the call of the peacock to connote a female’s desire to be reunited with her lover.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[2]} src={Peacock_3} />
                                    <button onClick={() => handlePlayPause(2)}>
                                        <img src={isPlaying[2] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[2] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p className="Peacockresponsive">A traditional bandish set in the raag Gaud Malhar, which is typically prescribed for the monsoon.</p>
                                    <p className="secndpara Peacockresponsive" >Name of composition : Balamaa bahaara aaee daadura bole<br />Singer : DV Paluskar</p>
                                </div>
                                <img src={Slide5Imgmob} alt="" className="bottomImg" id="Peacockimggg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mainOutBox mainBox3" >
                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        <img src={Slide2Blue} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2>Koel</h2>
                                <p className="description">The Koel has been the muse of great poets and writers and is mentioned in numerous <i>Thumri</i>, <i>Dadra</i>, and <i>Kajari</i> compositions of Hindustani music. The song of the bird is often associated with monsoon and with narratives of forlorn lovers.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[3]} src={Koel_4} />
                                    <button onClick={() => handlePlayPause(3)}>
                                        <img src={isPlaying[3] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[3] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p>A <i>dadra</i> that likens the Koel’s call to the stab of a knife into the heart of the forlorn lover.</p>
                                    <p className="secndpara" ><i>Name of composition: Koyaliya Mat Kar Pukar<br />Singer: Begum Akhtar</i></p>
                                </div>
                                <img src={Slide6Img1} alt="" className="bottomImg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        <img src={Slide2Blue} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2>Swan</h2>
                                <p className="description">Apart from ragas bearing the names of the swan such as Hansakinkini or Hamsadhwani, the swan is used as a metaphor for Krishna in the 12th-century poet, Jayadeva's Ashtapadi and as a symbol for the individual spirit in the Nirguna music.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[4]} src={Swan_5} />
                                    <button onClick={() => handlePlayPause(4)}>
                                        <img src={isPlaying[4] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[4] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p id='Swanresponsive' >An <i>Ashtapadi</i>, written by Jayadeva, where the swan is a metaphor for Lord Krishna.</p>
                                    <p className="secndpara" id='Swanresponsive' ><i>Name of composition : Ashtapadi <br />Singer : Jitendra Abhisheki</i></p>
                                </div>
                                <img src={Slide6Img2} alt="" className="bottomImg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        <img src={Slide2Blue} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2>Crow</h2>
                                <p className="description">Although crows are seen as the link between the worlds of the living and the dead in Indian mythology, <i>Khayal</i> and <i>Dadra</i> compositions in Hindustani music frequently refer to the crow as a messenger of lovers.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[5]} src={Crow30_6} />
                                    <button onClick={() => handlePlayPause(5)}>
                                        <img src={isPlaying[5] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[5] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p id="Crowresponsive" >A <i>dadra</i>  that employs the image of a crow carrying a message to the beloved.</p>
                                    <p className="secndpara" id="Crowresponsive" ><i>Name of composition: Chhai Ghata Ghan Ghor<br />Singer:  Shobha Gurtu</i></p>
                                </div>
                                <img src={Slide7Imgmob2} alt="" className="bottomImg  bottomImgCrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------------------------------------- GREEN ---------------------------------------------------------------- */}
            <div className="mainOutBox mainBox4" >

                <div className="firstBox">
                    <div className="firstInnerBox">
                        {/* <img src={SliderDeskGreen} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide2Green2} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="firstInnerMainBox"  >
                            <h2>Treasured in Folk Music</h2>
                            <div className={styles.imgMain}>
                                <img src={Slide7Img2} alt="Image" />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskGreen} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2Green} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <p className="description">Folk music serves as intimate documentation of indigenous bird species, their geographical spread, and their cultural significance to communities. Folk songs of the Solegas who live in the Biligiri Rangan Hills of Karnataka are replete with verses on the Swallow (whom they call <i>man-hakki</i> or mud bird), Red-vented Bulbul, Spotted Dove, Koel, Peacock, Quail.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[6]} src={Geena_Solega_folk_7} />
                                    <button onClick={() => handlePlayPause(6)}>
                                        <img src={isPlaying[6] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[6] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p>The Plum-headed Parakeet, also known as Geena in Solega parlance is one of many birds documented in the traditions of their singing.</p>
                                    <p className="secndpara" ><i>Song: Geena<br />Singers: Solegas from BR hill area</i></p>
                                </div>
                                <img src={Slide8Img1} alt="" className="bottomImg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskGreen} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2Green} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <p className="description">Rajastani folk songs feature the migratory bird, kurjan (Demoiselle Crane). In Chhattisgarh, the Gond community has an entire collection of songs dedicated to the parrot called Sua Geet. These songs are performed by Gond women in the post-Diwali harvest season even today.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <audio ref={audioRefs[7]} src={Sua_Geet_8} />
                                    <button onClick={() => handlePlayPause(7)}>
                                        <img src={isPlaying[7] ? puaseimg : play_arrow} width="20" height="20" />{isPlaying[7] ? "Play" : "Play"}
                                    </button>
                                </div>
                                <div className="innertText">
                                    <p>Women of the Gond community of Chattisgarh sing and dance to Sua Geet, celebrating the parrot, as they await the harvests of their land.</p>
                                    <p className="secndpara" ><i>Song : Sua Geet <br /> Singer: Women of the Gond community, Rajasthan</i></p>
                                </div>
                                <img src={Slide8Img2} alt="" className="bottomImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* --------------------------------------------------------------- PURPLE ---------------------------------------------------------------- */}
            <div className="mainOutBox mainBox5" >

                <div className="firstBox">
                    <div className="firstInnerBox">
                        {/* <img src={SliderDeskPurple} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide2Purple2} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="firstInnerMainBox">
                            <h2>The Call of Celluloid</h2>
                            <div className={styles.imgMain}>
                                <img src={Slide12Img1} alt="Image" className={styles.headerColumnImg} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskPurple} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2Purple} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <p className="description">Birds have ruled the roost in many Hindi film songs, from the <i>cheel</i> or Black Kite in Half Ticket to the <i>masakali</i>, or Indian fantail pigeon, in Delhi 6. They’ve been used as idioms (<i>Jhoot Bole Kauwa Kaate</i>, Bobby, 1978), romanticised (<i>Bole Re Papihara</i>, Guddi, 1971), celebrated as messengers of love (<i>Kabootar ja ja</i>, Maine Pyar Kiya, 1989), and used to symbolise free-spiritedness (<i>Panchi Banoon Udti Phiroon</i>, Chori Chori, 1956).</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/56AUdC9mn4E')} >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p className="secndpara"><i>Bole Re Papihara from the 1971 movie, Guddi, is sung by Vani Jairam and written by Gulzar. </i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskPurple} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2Purple} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <p className="description">Birds have made many appearances in south Indian film music too. The <i>gili</i> (Parrot in Kannada), <i>Ramachilaka</i> (Rose-ringed Parakeet in Telugu), the <i>kuyil</i> (Koel in Tamil), and <i>neela ponman</i> (Kingfisher in Malayalam) have all been portrayed in songs as songsters or symbols of beauty and love.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/E0E-ftZ9kYY')} >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p className="secndpara"><i>The song “Pullinangal” from the Tamil movie Robot 2.0 (2019) is a touching dedication to birds and their wordless symphonies.</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* --------------------------------------------------------------- WHITE ---------------------------------------------------------------- */}
            <div className="mainOutBox mainBox6" style={{ color: "black" }}>

                <div className="firstBox">
                    <div className="firstInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="desktopImg" /> */}
                        <img src={Slide2White2} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="firstInnerMainBox">
                            <h2>Musical Instruments</h2>
                            <div className={styles.imgMain}>
                                {/* <img src={Slide9Img1} alt="Image" style={{ width: "80%" }} className="desktopImg" /> */}
                                <img src={Slide9Img222} alt="Image" style={{ width: "80%" }} className="mobileImg tamburo" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2White} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2 id="Tausresponsive">Taus</h2>
                                <p className="description">Also called the <i>Mayuri</i> veena after its peacock-shaped resonator, this string instrument is made of rosewood or tun wood. The Taus is played with a bow made of horsehair. It is used as an accompaniment in Sikh devotional music called<i> Gurbani kirtan</i>.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/lgme4gUk7Hc')} id="Tausbtnresponsive" >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "#fff", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p>Credits: The Taus (‘Peacock’) |<br />
                                        Sandeep Singh | Raag Ahir Bhairav| Darbar</p>
                                </div>
                                <img src={Slide9Img2} alt="" className="bottomImg" id="Tausimgresponsive" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2White} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div style={{ position: "relative" }}>
                                <h2>Tarpa</h2>
                                <p className="description descriptionTarpa">This is a hornpipe mainly associated with the folk music tradition of western India. Designed with a peacock head on one end and a broad funnel-like opening at the other, the sonorous beauty of the instrument shines through when it is played solo.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://youtube.com/shorts/J14LdG_6EbY?si=fu1gTj28UXAv6FzQ')} >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "#fff", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    {/* <p>Credits:The Tarpa | Folk Music of Dang | Ahwa from <br />
                                        Gujarat | Sristi.org</p> */}
                                    <p>Credits : Tarpa Player | Santosh Nimbalkar</p>
                                </div>
                                <img src={Slide10Img1} alt="" className="bottomImg bottomImgTarpa" style={{ bottom: "45%", width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mainOutBox mainBox7" style={{ color: "black", marginBottom: "150px" }}>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2White} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div style={{ position: "relative" }}>
                                <h2>Bulbul Tarang</h2>
                                <p className="description">The name of this stringed instrument from Punjab literally translates to ‘waves of nightingales’. It is considered to be an Indian version of the Japanese stringed instrument, <i>Taishogoto</i>, and is used to accompany vocal music.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/XCNo_hh07KI')} id="BulbulTarangbtn" >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "#fff", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p>Credits: Bulbul Tarang | Unknown</p>
                                </div>
                                <img src={Slide10Img2} alt="" className="bottomImg" style={{ bottom: "50%", width: "90%" }} id="BulbulTarangresponsive" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2White} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2 id="MayuriYaazhresponsive">Mayuri Yaazh</h2>
                                <p className="description">This is an open-stringed Harp that finds mention in ancient Tamil texts. The instrument weighs around three kilograms and is made from the wood of the jackfruit tree. It gets its name from its resonator that is shaped like a Peacock. The 12-stringed instrument is played vertically, balanced on two sturdy clawed feet carved at its base.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/31ZksEch0OE')} id="MayuriYaazhbtn" >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "#fff", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p>Credits: The Sound of Yazh | Uru Instruments</p>
                                </div>
                                <img src={Slide11Img1} alt="" className="bottomImg" id="MayuriYaazhimg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secoundBox">
                    <div className="secoundInnerBox">
                        {/* <img src={SliderDeskWhite} alt="" style={{ width: "100%", height: "100%" }} className="backgroundImg desktopImg" /> */}
                        <img src={Slide2White} alt="" style={{ width: "100%", height: "100%" }} className="mobileImg" />
                        <div className="secoundInnerMainBox" >
                            <div>
                                <h2 id="BirdWhistleresponsive">Bird Whistle</h2>
                                <p className="description">A hand-held mouth-blown instrument, a bird whistle, is often made of terracotta, metal, wood, or plastic. It can closely mimic the sound of a variety of bird species and can provide sounds with rhythmic accuracy. Whistles and rattles shaped like the Partridge or Dove have been found during excavations at Indus valley civilization sites.</p>
                                <div className="audioVideoButton" style={{ marginBottom: "20px" }}>
                                    <button onClick={() => ShowModal('https://www.youtube.com/embed/djFSVfPOREw')} id="BirdWhistlebtn" >
                                        <img src={play_arrow} width="20" height="20" /><span>watch</span>
                                    </button>
                                    {model ?
                                        <div style={{ height: "100vh ", width: "100vw", background: "rgb(0 0 0 / 84%)", position: " fixed", top: " 0", left: " 0", zIndex: " 999999999999", display: " flex", alignItems: " center", justifyContent: "center" }}>
                                            <div style={{ width: "80%", minHeight: "100px", background: "", borderRadius: "20px", position: "relative", padding: "2px" }} >
                                                <div style={{ position: "absolute", right: "10px", top: "-30px", color: "#fff", fontSize: "25px", cursor: "pointer" }} onClick={hideModel}>X</div>
                                                <div style={{ height: "60vh", color: "#000" }}>
                                                    <iframe src={video} height="100%" width="100%" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="innertText">
                                    <p>Credits: Terracotta bird whistle | Arnav</p>
                                </div>
                                <img src={Slide11Img2} alt="" className="bottomImg" id="Whistleresponsive" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div style={{ minHeight: "13vh", width: "100vw", background: "none ", padding: "0", position: "relative" }}>
                <div style={{ width: "100%", padding: "0%" }}>

                    <button style={{ border: "none", width: "100vw", padding: "0%", background: "none" }}>
                        <Link to="/son">
                            <img src={GoToSongsOfNature} style={{ height: "auto", width: "100vw", padding: "0", position: "absolute", left: "0" }} />
                        </Link>
                    </button>
                </div>
            </div>
        </ >
    )
}

export default BirdsInMusicMobile