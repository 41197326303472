import React from 'react';
import emilyD from '../../../assets/images/natures-symphony/emilyD.png';
import '../../../css/natures-symphony/Emily.css';

const OrangeEmily = () => {
    return (
        <div className="mainorangeEmily" >


            <p className='orangeEmilyPara'>
                “Hope is the thing with feathers<br />
                That perches in the soul, <br />
                And sings the tune without the words, <br />
                And never stops at all" <br />
                - Emily Dickinson
            </p>


            <img className="emilyDickonson1 womanhideshow"  src={emilyD} alt="/" />

        </div>
    );
}

export default OrangeEmily;
