import React from 'react';
import '../../../css/natures-symphony/Tutor.css';
import '../../../css/natures-symphony/GreyStone.css';
import '../../../css/natures-symphony/SkinEar.css';
import '../../../css/natures-symphony/FinalGrey.css'
import ear from '../../../assets/images/natures-symphony/ear.png';
import ear122 from '../../../assets/images/natures-symphony/ear122.png';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import strokeorange from '../../../assets/images/natures-symphony/strokeorange.png';
import controller from '../../../assets/images/natures-symphony/controller.png';
import Artboard from '../../../assets/images/natures-symphony/Artboard.png';
import GameHeading from '../../../assets/images/natures-symphony/GameHeading.png';
import { useNavigate } from 'react-router-dom';
import VideoPopup from './VideoPopup';

const FinalGrey = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const navigate = useNavigate();

  const goToGame = () => {
    navigate('/gameLand');
  }
  // const gotoloreLegends = ()=>{
  //   navigate(`/lore`);
  // };

  return (
    <div className="mainFinalGrey"

    //    style={{display:'flex',
    //    width: '142vw',
    //  position: 'relative',
    //  overflow: 'hidden',
    //  zIndex: '-1',
    //   height:'100vh',
    // backgroundColor:'#333333' }}
    >

      <img className='skinEarimg hidemobile' id='skinEarimg2' src={ear122} alt='/' />
      {/* <img className='skinEarimg showMobile' id='skinEarimg2' src={ear122} alt='/' /> */}
      {/* <img className='skinEarimg hidemobile' id='skinEarimg2' src={ear122} alt='/' /> */}
      <p className='finalGreyPara' >There are studies that suggest that listening to natural sounds like birds singing helps us de-stress, relax and focus better.  Successful experiments have been done in the West where having birdsong as background music in public spaces has been noted to improve peoples’ moods and create a peaceful ambience.
      </p>
      <button className='videoButton' id='finalGreyVideoButton' onClick={handleOpen}> <a><img className='videoIcon' src={videoButton} alt='/' /></a>Watch a video</button>
      <VideoPopup
        vurl="https://www.youtube.com/embed/LV56s6Z_Bm0"

        close={handleClose}
        vopen={open}
      />
      <img className='strokeBlue' id='orange' src={strokeorange} alt='/' />
      <img className='controller' src={controller} alt='/' />
      <img className='artboard' src={Artboard} alt='/' />
      <img className='gameHeading' src={GameHeading} alt='/' />
      <p className='syrinxPara' id='GamePara'> Birdhouse is a unique game featuring Indian birds and their habitats. Select a bird and try to match it with where it lives. The game is a learning opportunity to know about our feathery friends. </p>
      <button id='gameButton' onClick={goToGame}>Start Game</button>

    </div>
  );
}

export default FinalGrey;
