import React from 'react'
import VideoPopup from './VideoPopup';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import '../../../css/natures-symphony/TutorGreyMobile.css';
import strokeblue2 from '../../../assets/images/natures-symphony/strokeblue2.png';
import strokeblue from '../../../assets/images/natures-symphony/strokeblue.png';
import Factoidlotus from '../../../assets/images/natures-symphony/Factoidlotus.png';

const TutorSkinMobile = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className="TutorSkinMobilemain">
            <div className='TutorSkinMobilemainpara' >
                <p className=' '>By rehearsing songs, they eventually master pitch, rhythm and structure. While nestlings may sound generic, adult songbirds have a refined and extensive repertoire. Adult songbirds like the Oriental Magpie-Robin can sing more than 80 different song types.
                </p>
                </div>
            <button className='videoButton' id='tutorVideoButton' onClick={handleOpen}> <a><img className='videoIcon' src={videoButton} alt='/' /></a>Watch a video</button>
            <VideoPopup
                vurl="https://www.youtube.com/embed/H0152b17tzw"

                close={handleClose}
                vopen={open}
            />
            <div>
                <img className="strokeBluenew" id=" " src={strokeblue2} alt="/" />
                <img className='factoidLotus' id='factoidLotusTutor' src={Factoidlotus} alt='/' />

                < div className="mobileipadDrawn">
                    <h1>Factoid:</h1>
                    <h2>Dawn Chorus</h2>
                </div>
                <p className='syrinxPara' id='factoidParaTutor'>It is not only great <i>vidwans</i> and <i>ustads</i> who advocate morning <i>riyaaz</i>, birds do too! Birds all over the world sing the most at sunrise. The marvellous show they put out at this time of day is called the dawn chorus. </p>

            </div>
        </div>
    )
}

export default TutorSkinMobile