import React, { useEffect, useState } from "react";
import "../../css/landing-page/SectionDesc.css";
import uLogo from "../../assets/images/landing-page/unnamed-logo-black.png";
import secOne from "../../assets/images/landing-page/natures-symphony-illustration.png";
import { Button, Container } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { Navbar, NavbarBrand } from "reactstrap";
import Customnavbar from "../Customnavbar/Customnavbar";

function NaturesSymphonyDesc(props) {
  const navigate = useNavigate();

  
  const goToNature = () => {
    navigate("/naturesymphony");
  };
  const [browserClass, setbrowserClass] = useState("natures-symphony-visit-but-safari");

  useEffect(() => {
    if(navigator) {
      let userAgentString = navigator.userAgent;
      if(userAgentString) {
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        if ((chromeAgent) && (safariAgent)) {
          setbrowserClass("natures-symphony-visit-but");
        }
      }
    }
  }, [])

  return (
    <Container
      id="natures-symphony-desc"
      style={{ display: props.isActive ? "block" : "none" }}
    >
      <Navbar>
        {/* <NavbarBrand href="/menu">
          <img
            src={uLogo}
            width={93}
            height={80}
            alt="birdsong-logo"
            className="unnamed-logo"
          />
        </NavbarBrand> */}
        <Customnavbar imglogo={uLogo} handleClose={props.handleClose} id="close-natures-symphony" />
      </Navbar>
      <p className="section-heading" id="natures-symphony-heading">
        Nature's Symphony
      </p>
      <p className="section-description" id="desc-one">
        Explore the musical world of birds through their songs, calls and unique physiology.
      </p>
      <div className="illustration">
        <img
          src={secOne}
          width={750}
          height={700}
          alt="nature's-symphony-illustration"
          id="chakra"
        />
      </div>
      <Button 
        style={{zIndex:1}}
        className="menu-option"
        id={browserClass}
        onClick={goToNature}
      >
        Visit the section&nbsp;&nbsp;
        <BsArrowRight size={30} color={"black"} />
      </Button>

      <VscChromeClose
        size={30}
        color={"black"}
        className="close-button"
        id="close-natures-symphony"
        onClick={props.handleClose}

      />
    </Container>
  );
}

export default NaturesSymphonyDesc;
