import React from "react";
import { FiPlay, FiPause } from "react-icons/fi";
let currentPlaying = null;
var that = null;

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index,
      url: this.props.url,
      buttonText: this.props.buttonText,
      size: this.props.size,
      className: this.props.className,
      play: false,
      audio: new Audio(this.props.url),
    };

    this.handleAudioPlayback = this.handleAudioPlayback.bind(this);
  }
  componentWillUnmount() {
    this.state.audio.pause();
  }

  handleAudioPlayback() {
    this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
        if (currentPlaying != null) {
          currentPlaying.pause();
          that.setState({ play: !that.state.play });
        }
        this.state.audio.play();
        currentPlaying = this.state.audio;
        that = this;
      } else {
        this.state.audio.pause();
        currentPlaying = null;
      }
    });
  }

  render() {
    return (
      <>
        {this.state.play ? (
          <button
            className={this.state.className}
            onClick={this.handleAudioPlayback}
          >
            <FiPause size={this.state.size} />
            &nbsp;{this.state.buttonText}
          </button>
        ) : (
          <div
            className={this.state.className}
            onClick={this.handleAudioPlayback}
          >
            <FiPlay size={this.state.size} />
            &nbsp;{this.state.buttonText}
          </div>
        )}
      </>
    );
  }
}

export default AudioPlayer;
