import React from 'react';
import BirdLotus from '../../../assets/images/natures-symphony/BirdLotus.png';
import emilyD from '../../../assets/images/natures-symphony/emilyD.png';
import '../../../css/natures-symphony/Emily.css';

const Emily = () => {
    return (
        <div className="emilymainsection" >
            <img className="BirdLotus" src={BirdLotus} alt="/" />
            <div className='NSheading' >
                <h1 id='Nhead'>Nature's</h1>
                <h1 id='Shead'>Symphony</h1>
            </div>

            <img className="emilyDickonson" src={emilyD} alt="/" />


        </div>
    );
}

export default Emily;
