import Menu from "./Menu";
import PageFive from "./PageFive";
import PageFourPartOne from "./PageFourPartOne";
import PageFourPartTwo from "./PageFourPartTwo";
import PageOne from "./PageOne";
import PageThree from "./PageThree";
import PageTwo from "./PageTwo";
import React from "react";
import "../../css/landing-page/PageTwo.css";
import "../../css/landing-page/LandingPage.css";
import { Container } from "reactstrap";

class LandingPage extends React.Component{
  constructor(props){
    super();
    this.state = {
      display:"block",
      slideDuration: 5,
      currentSlide:1,
      animationCount:0
    }
    this.handleSlideCompletion = this.handleSlideCompletion.bind(this);
  }
  handleSlideCompletion(e){
    for(let i=1;i<=6;i++){
      var slide = document.getElementById("page-slide-"+i);
      slide.classList.add("slide-init");
    }
  }
  componentDidMount(){
   
    this.startSlideShow();
  }

  startSlideShow(){

    var currentSlide = this.state.currentSlide;

    var bufferSlides = function(){
      for(let i=2;i<=6;i++){
        var slide = document.getElementById("page-slide-"+i);
        slide.classList.remove("slide-init");
      }
  }
    

    var switchSlide = function(){

        if(currentSlide == 6){
          var menu = document.getElementById("page-slide-menu");
          menu.classList.remove("slide-init");

          var menuItems = document.querySelectorAll("#page-slide-menu > #parent > #pg-six > #overlay > #landing-menu-items")[0];
          menuItems.classList.remove("landing-menu");
          menuItems.classList.add("translate-up");

          var currentSlideElement = document.getElementById("page-slide-"+currentSlide);
          currentSlideElement.classList.add("fade-out-slide");
          clearInterval(slideShow);
        }

        else{
          var currentSlideElement = document.getElementById("page-slide-"+currentSlide);
          currentSlideElement.classList.add("fade-out-slide");
          
          if(currentSlide == 4){
            var slideFourFadeInText = document.querySelectorAll("#page-slide-5 > #pg-four > #content-four-2")[0];
            slideFourFadeInText.classList.add("fade-in-slide");
            slideFourFadeInText.classList.remove("slide-init");
          }
          currentSlide++;
        }
    }

    setTimeout(bufferSlides,4000);

    var slideShow = setInterval(switchSlide,this.state.slideDuration*1000)
  }

  render(){
    return(
      <Container>
        <div id="page-slide-menu" className = "slide-init" onAnimationEnd = {this.handleSlideCompletion}>
          <Menu/>
        </div>
        <div id = "page-slide-6" className = "slide-init">
          <PageFive/>
        </div>
        <div id = "page-slide-5" className = "slide-init">
          <PageFourPartTwo/>
        </div >
        <div id = "page-slide-4" className = "slide-init">
          <PageFourPartOne/>
        </div >
        <div id = "page-slide-3" className = "slide-init">
          <PageThree/>
        </div>
        <div id = "page-slide-2" className = "slide-init">
          <PageTwo/>
        </div>
        <div id = "page-slide-1">
          <PageOne />
        </div>
      </Container>   
    )
  }
}


export default LandingPage;
