import React from 'react';
import styles from "../../../css/birds-in-music/BirdsInMusic.module.css";
// import Slide2Img1 from "../../../assets/images/birds-in-music/slide2-img1.png";
// import Slide2Img2 from "../../../assets/images/birds-in-music/slide2-img2.png";
// import Slide2Img3 from "../../../assets/images/birds-in-music/slide2-img3.png";


class BirdsInMusicSlide2 extends React.Component {
    constructor(props){
        super();      
    }

    render(){
        return (
            <div className = {styles.BirdsInMusicSlide2} styles = {styles.BirdsInMusicSlide2} style={{border:"0px solid red"}}>
                <div className = {styles.BirdsInMusicSlide2Header} styles = {styles.BirdsInMusicSlide2Header}>
                    <p>The musical worlds of birds and humans intersect with delightful parallels in their compositions. 
                        Music is built on characteristic elements, which can also be found in the singing of birds. 
                        Music is a structured set of notes that is pleasing to the ear  and may be used to express ideas and emotions. 
                        These are also referred to as melodies. Birdsongs, too, have organised sets of notes that express and communicate information. 
                        Birds use ambience pitch to sing their song, just as musicians use the tanpura or the drone to sing in pitch. 
                        As the frequency of background sounds rises, birds raise the pitch of their song.
                    </p>
                </div>

                {/* <div className = {styles.BirdsInMusicSlide2Columns} styles = {styles.BirdsInMusicSlide2Columns}>
                    <div className = {styles.BirdsInMusicSlide2ColumnItem} styles = {styles.BirdsInMusicSlide2ColumnItem}>
                        <div className = {styles.BirdsInMusicSlide2ColumnHeader} styles = {styles.BirdsInMusicSlide2ColumnHeader}>
                            <h3>Hamsa</h3>
                        </div>
                        <div className = {styles.BirdsInMusicSlide2ColumnText} styles = {styles.BirdsInMusicSlide2ColumnText}>
                        <p>Hamsadhwani, Hamsanandi, Hamsadeepika, Hamsa Nandini, Hamsakalyani, Balahamsa,Hamsa</p>
                        </div>
                    </div>

                    <div className = {styles.BirdsInMusicSlide2ColumnItem} styles = {styles.BirdsInMusicSlide2ColumnItem}>
                        <div className = {styles.BirdsInMusicSlide2ColumnHeader} styles = {styles.BirdsInMusicSlide2ColumnHeader}>
                            <h3>Abheri</h3>
                        </div>
                        <div className = {styles.BirdsInMusicSlide2ColumnText} styles = {styles.BirdsInMusicSlide2ColumnText}>
                        <p>Hansakinkini, Vihang</p>
                        </div>
                    </div>

                    <div className = {styles.BirdsInMusicSlide2ColumnItem} styles = {styles.BirdsInMusicSlide2ColumnItem}>
                        <div className = {styles.BirdsInMusicSlide2ColumnHeader} styles = {styles.BirdsInMusicSlide2ColumnHeader}>
                            <h3>Kokila</h3>
                        </div>
                        <div className = {styles.BirdsInMusicSlide2ColumnText} styles = {styles.BirdsInMusicSlide2ColumnText}>
                        <p>Kokila, Kokilapriya, Kokilaaravam, Kokilavarali, Madhurakokila, Kokilabhaashini</p>
                        </div>
                    </div>

                    <div className = {styles.BirdsInMusicSlide2ColumnItem} styles = {styles.BirdsInMusicSlide2ColumnItem}>
                        <div className = {styles.BirdsInMusicSlide2ColumnHeader} styles = {styles.BirdsInMusicSlide2ColumnHeader}>
                            <h3>Garuda</h3>
                        </div>
                        <div className = {styles.BirdsInMusicSlide2ColumnText} styles = {styles.BirdsInMusicSlide2ColumnText}>
                        <p>Garudadhwani</p>
                        </div>
                    </div>
                </div>

                <div className = {styles.BirdsInMusicSlide2ImageRow} styles = {styles.BirdsInMusicSlide2ImageRow}>
                    <div className = {styles.BirdsInMusicSlide2Img1}>
                        <img src={Slide2Img1} style = {{width:"100%"}}></img>
                    </div>
                    <div className = {styles.BirdsInMusicSlide2Img2}>
                        <img src={Slide2Img2} style = {{width:"100%"}}></img>
                    </div>
                    <div className = {styles.BirdsInMusicSlide2Img3}>
                        <img src={Slide2Img3} style = {{width:"100%"}}></img>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default BirdsInMusicSlide2