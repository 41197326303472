import React from "react";
import "../../css/landing-page/PageTwo.css";
import { Container, Button } from "reactstrap";

function PageTwo() {
  return (
    <Container id="pg-two">
      <div id="content-two">
        <p>
          - empty skies, silent backyards, muted forests and humans without the
          spirit of wings.
        </p>
      </div>
      <a href="/menu">
        <Button className="skip">skip intro</Button>
      </a>
    </Container>
  );
}

export default PageTwo;
