import React from 'react';
import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";
import LandingPageImg1 from "../../../assets/images/birds-in-music/slide1-img1.png";
import LandingPageImg2 from "../../../assets/images/birds-in-music/slide1-img2.png";

class BirdsInMusicLandingPage extends React.Component {
    constructor(props){
        super();      
    }

    render(){
        return (
            <div className = {styles.BirdsInMusicLandingPage} styles = {styles.BirdsInMusicLandingPage}>
                {/* =========== desktop ========= */}
                <div className={styles.desktopBirdsInMusic}>
                    <div className = {styles.BirdsInMusicLandingPageTitle} styles = {styles.BirdsInMusicLandingPageTitle}>
                        <span style={{position:'relative', top:"8vh"}}>Birds</span><br/>
                        <span className={styles.Birdsin} >In</span>Music
                    </div>
                </div>
                {/* ======== Mobile and Ipad ============== */}
                <div className={styles.MobileBirdsInMusic}>
                    <div className={styles.BirdsInMusicLandingPageTitle} styles={styles.BirdsInMusicLandingPageTitle}>
                        Birds<br />
                        <span className={styles.Birdsin} ><sup>In</sup></span>Music
                    </div>
                </div>


                <div className={styles.BirdsInMusicLandingPageImg1} styles={styles.BirdsInMusicLandingPageImg1} >
                    <div className={styles.imghidemobile}><img src={LandingPageImg1} style={{ width: "100%" }}></img> </div>
                </div>
                <div className= {styles.BirdsInMusicLandingPageImg2} styles = {styles.BirdsInMusicLandingPageImg2}>
                    <img src={LandingPageImg2} style={{ width: "100%" }} className={styles.birdsLandingPageImg2}></img>
                </div>
            </div>
        )
    }
}

export default BirdsInMusicLandingPage