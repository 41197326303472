import React from 'react'
import img from "../../assets/images/birds-in-music/slide11-img2.png" 
import "../Customnavbar/Customnavbar.css"
import { Col, Container, Row } from 'reactstrap'
import SectionMenu from '../navigation/SectionMenu'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
const Customnavbar = (props) => {
    
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const navigate = useNavigate();

  return (
   <>
   <Container>
    <Row className='custommaindiv'>
        <Col style={{textAlign:"start"}}>
            {/* <a href="/menu" > */}
            <Link to="/menu" >
              <img id={props.id}  className='logonavclass' src={props.imglogo} onClick={props.handleClose} />
            </Link>
           {/* </a>  */}
        </Col>
        {props.menuicon && (
          <Col style={{textAlign:"end"}}>
          <img style={{width:"100%"}} src={props.menuicon} onClick={openMenu}/>
          </Col>
        )}
    </Row>
   </Container>
   <SectionMenu
        isActive={menuOpen}
        handleClose={closeMenu}
      />
   {/* <div className='custommaindiv'>
        <div>
            <img style={{width:"20%"}} src={props.imglogo}/>
        </div>
        
        <div style={{float:'right'}}>
            <img style={{width:"100%"}} src={props.menuicon}/>
        </div>
   </div> */}
   </>
  )
}

export default Customnavbar
