import "../../../css/natures-symphony/style.css";
import "../../../css/natures-symphony/showhorizontal.css";
import HorizontalScroll from "react-scroll-horizontal";
import { ParallaxProvider } from "react-scroll-parallax";
import SideBar from "./SideBar";
import Emily from "./Emily";
import OrangeEmily from './OrangeEmily'
import Syrinx from './Syrinx';
import SyrinxSkin from './SyrinxSkin';
import SyrinxSkin1 from './SyrinxSkin1';
import AppleRadio from './AppleRadio';
import AppleRadioSkin from "./AppleRadioSkin";
import OrangeNest from "./OrangeNest";
import TutorGrey from "./TutorGrey";
import TutorSkin from "./TutorSkin";
import GreyStone from "./GreyStone";
import SkinEar from "./SkinEar";
import FinalGrey from "./FinalGrey";
import GotoInloreLegends from "./GotoInloreLegends";
import SyrinxMobile from "./SyrinxMobile";
import TutorGreyMobile from "./TutorGreyMobile";
import TutorSkinMobile from "./TutorSkinMobile";

export default function NatureSymphony() {

  return (
    <ParallaxProvider scrollAxis='Horizontal' >
      <div className="hideshowhorizontal">
        <div className="flex fill center container" id="App">
          <SideBar />
          <HorizontalScroll reverseScroll={false} id="Toggle" >

            <Emily />
            <OrangeEmily />
            <Syrinx />
            <SyrinxSkin />
            <AppleRadio />
            <AppleRadioSkin />
            <OrangeNest />
            <TutorGrey />
            <TutorSkin />
            <GreyStone />
            <SkinEar />
            <FinalGrey />
          </HorizontalScroll>

        </div>
      </div>
      
      <div className="hideshowhorizontalmoble">
        
        <SideBar />
        <Emily />
        <OrangeEmily />
        {/* <Syrinx /> */}
        <SyrinxMobile />
        <SyrinxSkin />
        <SyrinxSkin1 />
        <AppleRadio />
        {/* <AppleRadioSkin /> */}
        <OrangeNest />
        {/* mobile new */}
        {/* <TutorGrey /> */}
        <TutorGreyMobile />
        {/* <TutorSkin /> */}
        <TutorSkinMobile />
        <GreyStone />
        <SkinEar />
        <FinalGrey />
        <GotoInloreLegends />

      </div>
    </ParallaxProvider>
  );
}