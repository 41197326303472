import React, { Component } from 'react'
import PlayButton from "../../../assets/images/birds-in-music/play-button.png";
import PauseButton from "../../../assets/images/birds-in-music/pause-button.png";

import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";


class AudioPlayer extends React.Component {
    constructor(props) {
    super(props);
    this.state = {

      url: props.url,  
      play: props.audioConfig.play,
      pause: props.audioConfig.pause,
      audio: new Audio(props.url),
    };
    this.handleAudioPlayback = this.handleAudioPlayback.bind(this);
    this.requestAudioPlayback = props.requestAudioPlayback;
    this.requestAudioPause = props.requestAudioPause;
    this.itemIndex = props.itemIndex;


  }

  handleAudioPlayback(){
    if(this.state.pause){
      this.requestAudioPlayback(this.itemIndex,this.state.audio)
    }
    if(this.state.play){
      this.requestAudioPause(this.itemIndex)
    }
  }

  componentDidUpdate(prevProps){
    if(this.state.play != this.props.audioConfig.play){
      if(this.props.audioConfig.play){
        this.setState({
          play:true,
          pause:false
        });
        this.state.audio.play();
      }
      else{
        this.setState({
          play:false,
          pause:true
        });
        this.state.audio.pause();
      }
    }

  }


  
  render() {
    return (
        <div className = {styles.BirdsInMusicAudioButton} styles = {styles.BirdsInMusicAudioButton} onClick = {this.handleAudioPlayback}>
          <div className = {styles.BirdsInMusicPlayButton} styles = {styles.BirdsInMusicPlayButton} >
              {this.state.pause?<img className = "play-button-img"src = {PlayButton} style = {{width:"100%"}}></img>:<img className = "pause-button-img" src = {PauseButton} style = {{width:"130%"}}></img>}
          </div>
          <p>Play</p>
        </div>

    );
  }
}


export default AudioPlayer