import React from "react";
import "../../css/landing-page/PageOne.css";
import { Container, Button } from "reactstrap";

function PageOne() {
  return (
    <Container id="pg-one">
      <div id="content-one">
        <p>Imagine a world without birds</p>
      </div>
      <a href="/menu">
        <Button className="skip">skip intro</Button>
      </a>
    </Container>
  );
}

export default PageOne;
