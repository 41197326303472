import React from 'react'
import { useState } from 'react';
import '../../../../css/natures-symphony/game/Game.scss';
import { Modal } from '@material-ui/core';
import GameContainer from './GameContainer';
import Instructions from './Instructions';
import { useNavigate } from 'react-router-dom';

function Game() {
  let  sound, info, home, mute;

  const navigate = useNavigate();
  const [playSound, setPlaySound] = useState(true);
  const [showHtp, setShowHtp] = useState(false);
  const handleOpen = () => {
    setShowHtp(true);
  };

  const handleClick = () => {
    setPlaySound((playSound)=>!playSound);
  };
  
  const onClose = () => {
    setShowHtp(false);
  };

 
  const goToStartGame =()=>{
    navigate('/gameLand');
  }

  sound= require(`../../../../assets/images/natures-symphony/game/desktop/sound1.jpg`)
  mute= require(`../../../../assets/images/natures-symphony/game/desktop/mute1.jpg`)
  info= require(`../../../../assets/images/natures-symphony/game/desktop/info.jpg`)
  home = require(`../../../../assets/images/natures-symphony/game/desktop/homeIcon.jpg`)

  return (
    <div className='containerOuter'>
        <div className='containerInner'>
            <h1 className='header1'>bird</h1>
            <h1 className='header2'>house</h1>
            <div className='homeIcon'><button className='homeIconButton' onClick={goToStartGame}><img src={home} width="66px" height="56px" alt="pokeball" /></button></div>
            <div className='sound'>
            <button className='soundButton' onClick={handleClick}>
            <img src={playSound ? sound  : mute} width="33px" height="33px" alt="pokeball" />
            </button>
            </div>
            <div className='info'>
            <img src={info} width="36px" height="36px" alt="pokeball" />
            </div>
            <div className='howtoplay'>
              <button className='htpbutton' onClick={handleOpen}>how to play?</button>
            </div>
            <GameContainer playSound={playSound}/>
            <Modal
        open={showHtp}
        style = {{
          position : 'absolute',
          width : '47%',
          height : '70.3%',
          left: '21.3%',
          top: '17.4%',
          opacity: '0.92',
          backgroundColor: 'rgb(10,10,10)',
          borderRadius : '24px' 
          
        }}
      >
        <div>
        <Instructions/>
        <button className='backButton'  onClick={onClose}><span className='bButton'>Back</span></button> 
        </div>   
      </Modal>
        </div>
       
    </div>
  )
}

export default Game