import React, { useState } from 'react'
import { FiPlay, FiPause } from "react-icons/fi";

import GroupCamera from "../../assets/images/songs-of-nature/GroupCamera.png";
import WhiteRumpedBird from "../../assets/images/songs-of-nature/WhiteRumpedBird.png";
import MalabarWhistlingBird from "../../assets/images/songs-of-nature/MalabarWhistlingBird.png"
import RustyBelliedBird from "../../assets/images/songs-of-nature/RustyBelliedBird.png"
import HimalayanThrushBird from "../../assets/images/songs-of-nature/HimalayanThrush Bird.png"
import PuffThroatedBabblerBird from "../../assets/images/songs-of-nature/PuffThroatedBabblerBird .png"
import PurpleSunbirdBird from "../../assets/images/songs-of-nature/PurpleSunbirdBird.png"
import GreyWinedg from "../../assets/images/songs-of-nature/GreyWinedg.png"
import OrientalMagpie from "../../assets/images/songs-of-nature/OrientalMagpie.png"
import AsianBird from "../../assets/images/songs-of-nature/AsianBird.png"
import RedwhiskeredBulbulBird from "../../assets/images/songs-of-nature/Red-whiskered BulbulBird.png"


const BirdsectionMobile = () => {

    const [isPlaying, setIsPlaying] = useState(new Array(10).fill(false));
    const audioRefs = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null)];

    const handlePlayPause = (index) => {
        // console.log("isPlaying===", isPlaying);
        // alert("fjgd")
        const newIsPlaying = [...isPlaying];

        if (newIsPlaying[index]) {
            audioRefs[index].current.pause();
        }
        else {
            // for (let i = 0; i < 8; i++) {
            //     audioRefs[i].current.pause();
            // }
            audioRefs[index].current.play();

        }
        newIsPlaying[index] = !newIsPlaying[index];
        setIsPlaying(newIsPlaying);
        // console.log("newIsPlaying=========",newIsPlaying);
    };

    return (
        <>
            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>White-rumped Shama</h1>

                        <div className="Birdsecondfirst" >
                            <img src={WhiteRumpedBird} alt="himalyan-thrush" />
                        </div>
                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2 >Albin Jacob</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The voice of this species is rich and melodious. One of the first recordings of a birdsong that was ever made was of the White-rumped Shama in 1889 using an Edison wax cylinder by Ludwig Koch in Germany. </p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[0]} src={`https://mcdn.podbean.com/mf/web/dxmpxs/Indian_Shamabhjm4.mp3`} />
                                <button onClick={() => handlePlayPause(0)} className="audioPlayPauseButton">
                                    {isPlaying[0] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Malabar Whistling-Thrush</h1>

                        <div className="Birdsecondfirst" >
                            <img src={MalabarWhistlingBird} alt="MalabarWhistlingBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2 >Gavin Emmons</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The Malabar Whistling-Thrush is known as the whistling schoolboy because of the human-like whistling cries it makes at daybreak.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[1]} src={`https://mcdn.podbean.com/mf/web/m7cr2b/Malabar_whistling_thrush.mp3`} />
                                <button onClick={() => handlePlayPause(1)} className="audioPlayPauseButton">
                                    {isPlaying[1] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Rusty-bellied Shortwing</h1>

                        <div className="Birdsecondfirst" >
                            <img src={RustyBelliedBird} alt="RustyBelliedBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2 >Eejay Bhutan</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The Rusty-bellied Shortwing has a distinctive, high-pitched call consisting of a series of sharp whistles, often repeated in quick succession.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[2]} src={`https://mcdn.podbean.com/mf/web/piebd6/Rusty-bellied_Shortwing_-_Brachypteryx_hyperythra6v8bn.mp3`} />
                                <button onClick={() => handlePlayPause(2)} className="audioPlayPauseButton">
                                    {isPlaying[2] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Himalayan Thrush</h1>

                        <div className="Birdsecondfirst" >
                            <img src={HimalayanThrushBird} alt="HimalayanThrushBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2 >Aparajita Datta</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The Himalayan thrush has a complex, melodious call of whistles, trills, and chirps. Its impressive vocal range is an essential part of courtship and territorial behavior.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[3]} src={`https://mcdn.podbean.com/mf/web/a6ypcf/XC301071_-_Himalayan_Thrush_-_Zoothera_salimalii888yn.mp3`} />
                                <button onClick={() => handlePlayPause(3)} className="audioPlayPauseButton">
                                    {isPlaying[3] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Puff-throated Babbler</h1>

                        <div className="Birdsecondfirst" >
                            <img src={PuffThroatedBabblerBird} alt="PuffThroatedBabblerBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Manoj Karingamadathil</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p> Puff-throated Babblers forage in lively flocks on the forest floor and give out clear, rattling, and rasping calls.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[4]} src={`https://mcdn.podbean.com/mf/web/michea/Pellorneum_ruficeps_-_Puff-throated_Babbler_XC597726.mp3`} />
                                <button onClick={() => handlePlayPause(4)} className="audioPlayPauseButton">
                                    {isPlaying[4] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Purple sunbird</h1>

                        <div className="Birdsecondfirst" >
                            <img src={PurpleSunbirdBird} alt="himalyan-thrush" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Garima Bhatia</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p> Sunbirds are small passerine birds endemic to the Indian subcontinent. During the breeding season, males perform a courtship display by fluttering their wings in front of females while singing.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[5]} src={`https://mcdn.podbean.com/mf/web/d6nc79/Purple-rumped_Sunbird.mp3`} />
                                <button onClick={() => handlePlayPause(5)} className="audioPlayPauseButton">
                                    {isPlaying[5] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Grey-winged Blackbird</h1>

                        <div className="Birdsecondfirst" >
                            <img src={GreyWinedg} alt="GreyWinedg" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Khoitran1957</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The Grey-winged Blackbird's sweet and melodious song is generally slow-paced, with a rich and mellow tone. It can be heard throughout the day but is particularly prevalent in the early morning and late afternoon.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[6]} src={`https://mcdn.podbean.com/mf/web/6t6v7x/Grey-winged_Blackbird_-_Turdus_boulboul76yw5.mp3`} />
                                <button onClick={() => handlePlayPause(6)} className="audioPlayPauseButton">
                                    {isPlaying[6] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Oriental Magpie-Robin</h1>

                        <div className="Birdsecondfirst" >
                            <img src={OrientalMagpie} alt="OrientalMagpie" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Subadra Devi</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>The Oriental Magpie-Robin is renowned for its large repertoire of melodious songs, which feature a diverse range of notes and phrases. Their vocalizations are known for their complexity and produce an uplifting sound.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[7]} src={`https://mcdn.podbean.com/mf/web/ne794p/Oriental_Magpie-robin_Hareesha_AS9fb73.mp3`} />
                                <button onClick={() => handlePlayPause(7)} className="audioPlayPauseButton">
                                    {isPlaying[7] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Asian Koel</h1>

                        <div className="Birdsecondfirst" >
                            <img src={AsianBird} alt="AsianBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Gururaj Moorching</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p>These shy birds are found throughout the Indian subcontinent. Famous for its loud call, the female cuckoo lays eggs in the nests of crows or drongos.</p>
                            <div style={{ marginTop: "10%" }}>
                                <audio ref={audioRefs[8]} src={`https://mcdn.podbean.com/mf/web/ydyjff/Asian_Koel_-Viral_Joshi9r3dh.mp3`} />
                                <button onClick={() => handlePlayPause(8)} className="audioPlayPauseButton">
                                    {isPlaying[8] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="BirdsectionMobileMain">
                <div className="Birdcolorsection">
                    <div className="Birdfirstmain" >
                        <h1>Red-whiskered Bulbul</h1>

                        <div className="Birdsecondfirst" >
                            <img src={RedwhiskeredBulbulBird} alt="RedwhiskeredBulbulBird" />
                        </div>

                        <div className="BirdsecondSecond">
                            <div className="camera"><img src={GroupCamera} alt="GroupCamera" width={50} height={50} /></div>
                            <div className="cameraNameText"><h2>Ramki Srinivasan</h2></div>
                        </div>

                        <div className="BirdsecondThird">
                            <p> The Red-whiskered Bulbul, found in pairs or small groups in gardens, orchards, and open forests, sings a pleasant three-note whistle with a cheery and melodious tone, often repeated in quick succession.</p>
                            <div style={{ marginTop: "10%",border:"0px solid red" }}>
                                <audio ref={audioRefs[9]} src={`https://mcdn.podbean.com/mf/web/m7bu9i/Red-whiskered_Bulbul_Samira_Agnihotri8hjvd.mp3`} />
                                <button onClick={() => handlePlayPause(9)} className="audioPlayPauseButton">
                                    {isPlaying[9] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Birdsong</b>
                                </button>
                                {/* <button onClick={() => handlePlayPause(9)} className="audioPlayPauseButton">
                                    {isPlaying[9] ? <FiPause className="playpuse" /> : <FiPlay className="playpuse" />} &nbsp;<b>Listen birdsong</b>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BirdsectionMobile