import React from 'react'
import "../../../../css/natures-symphony/game/GameContainer.scss"
function FunFacts() {
    
    return (

<div className='funFact'>Fun Facts</div>
      
      );
}

export default FunFacts