import React, { useEffect, useState } from "react";
import "../../css/landing-page/SectionDesc.css";
import uLogo from "../../assets/images/landing-page/unnamed-logo-black.png";
import secFour from "../../assets/images/landing-page/songs-of-nature-illustration.png";
import { Button, Container } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Navbar, NavbarBrand } from "reactstrap";
import Customnavbar from "../Customnavbar/Customnavbar";

function SongsOfNatureDesc(props) {
  const [browserClass, setbrowserClass] = useState("songs-of-nature-visit-but-safari");

  useEffect(() => {
    if(navigator) {
      let userAgentString = navigator.userAgent;
      if(userAgentString) {
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        if ((chromeAgent) && (safariAgent)) {
          setbrowserClass("songs-of-nature-visit-but");
        }
      }
    }
  }, [])
  return (
    <Container
      id="songs-of-nature-desc"
      style={{ display: props.isActive ? "block" : "none" }}
    >
      <Navbar>
        {/* <NavbarBrand href="/menu">
          <img
            src={uLogo}
            width={93}
            height={80}
            alt="birdsong-logo"
            className="unnamed-logo"
          />
        </NavbarBrand> */}
        <Customnavbar imglogo={uLogo} handleClose={props.handleClose} id="close-songs-of-nature"/>
      </Navbar>
      <p className="section-heading" id="songs-of-nature-heading">
        Songs of Nature
      </p>
      <p className="section-description" id="desc-four">
        Enter the melodious world of Songbirds and listen to their tunes.
      </p>
      <div className="illustration">
        <img
          src={secFour}
          width={650}
          height={730}
          alt="songs-of-nature-illustration"
          id="kuruvi"
        />
      </div>
      <Link style={{zIndex:1}} to={"/son"}>
        <Button className="menu-option" id={browserClass}>
          Visit the section&nbsp;&nbsp;
          <BsArrowRight size={30} color={"black"} />
        </Button>
      </Link>
      <VscChromeClose
        size={30}
        color={"black"}
        className="close-button"
        id="close-songs-of-nature"
        onClick={props.handleClose}
      />
    </Container>
  );
}

export default SongsOfNatureDesc;
