import React from 'react';

import Iframe from 'react-iframe';
import { Modal, DialogContent } from '@material-ui/core';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const VideoPopup = ({ vurl, close, vopen }) => {

  return (


    <Modal
      open={vopen}

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
    >

      <div style={{
        justifyContent: "center"
        , alignItems: "center", marginTop: "10vh", marginLeft: "25vw"
      }}>


        <DialogContent style={{ border: "none", }}>

          {vopen ? (
            <button
              aria-label="close"
              onClick={close}
              style={{

                width: "0vw",
                backgroundColor: "rgba(0, 0, 0, 0)",
                border: "none"

              }}
            >
              <CloseOutlinedIcon style={{ color: "white", marginLeft: "49vw" }} />
            </button>
          ) : null}
        </DialogContent>

        <Iframe url={vurl}
          width="50%"
          height="55%"
          id="myId"
          className="myClassname"
          display="block"
          margin="auto"
          position="absolute"
        />
      </div>
    </Modal>

  );
}

export default VideoPopup;
