import React from 'react';
import '../../../css/natures-symphony/AppleRadio.css';
import appleradio from '../../../assets/images/natures-symphony/appleradio.png';

const AppleRadioSkin=()=>
{
return (
   
   <div style={{display:'flex',
   width: '53.02vw',
 position: 'relative',
 overflow: 'hidden',
 zIndex: '-1',
  height:'100vh',
backgroundColor:'#F4EACE' }}>
   
<img className='appleradioimg1' src={appleradio} alt='/' />
<h1  className='count' id='c3'>03</h1>
      <p className='para' id='p3'>Birds also communicate with each other through their songs and calls much like how we bond with friends over a chat.</p>
   </div>
);
}

export default AppleRadioSkin;
