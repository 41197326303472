import React from 'react';
import '../../../css/natures-symphony/Tutor.css';
import '../../../css/natures-symphony/GreyStone.css';
import stroke2 from '../../../assets/images/natures-symphony/stroke2.png';
import recordillustration from '../../../assets/images/natures-symphony/recordillustration.png';
import strokeblue from '../../../assets/images/natures-symphony/strokeblue.png';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import Rectangle from '../../../assets/images/natures-symphony/Rectangle.png';
import VectorStone from '../../../assets/images/natures-symphony/VectorStone.png';
import whitelayer from '../../../assets/images/natures-symphony/whitelayer.png';
import bluelayer from '../../../assets/images/natures-symphony/bluelayer.png';
import VideoPopup from './VideoPopup';

const GreyStone=()=>
{
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

return (
  <div className="mainGreyStone"
//    style={{display:'flex',
//    width: '149.47vw',
//  position: 'relative',
//  overflow: 'hidden',
//  zIndex: '-1',
//   height:'100vh',
// backgroundColor:'#333333' }}
>
   
  <div className='GreyStoneHeading'>Do songbirds sing the same songs?</div>
  <img className='strokeBlue'  id='strokeBlueStone' src={strokeblue} alt='/' />
  <p className='GreyStonePara'>Each songbird species has its own collection of songs. Some birds, on the other hand, are expert mimics and can imitate the melodies of other birds. The Greater Racket-tailed Drongo is regarded as one of the best mimics in the avian world. When exposed to humans, birds like parakeets and mynahs can mimic the human voice with startling accuracy.
</p>
<img  className='recordimg' src={recordillustration} alt='/' />
  <img  className='stroke2' src={stroke2} alt='/' />
 
  <p className='GreyStonePara'id='gsp2'>Bird sounds, like people, have dialects based on their geographic region. A Malabar Whistling-Thrush in Goa is likely to sound different from a Malabar Whistling-Thrush in Kerala, much as a Kannadiga from Dharwad has a dialect that is unique from a Kannadiga from Udupi.
</p>
<button className='videoButton' id='greyStoneVideoButton'onClick={handleOpen}> <a><img className='videoIcon' src={videoButton}  alt='/' /></a>Watch a video</button>
<VideoPopup 
       vurl="https://www.youtube.com/embed/A0LVkV17Puo"
     
       close={handleClose}
       vopen={open}
       />

<img className='stone'  src={Rectangle} alt='/' />
<img className='shadow' src={VectorStone} alt='/' />
<img className='wlayer' src={whitelayer} alt='/' />
<img className='blayer'  src={bluelayer} alt='/' />
   </div>
);
}


export default GreyStone;
