import React, { Component } from 'react'
import VideoPopup from "../utils/VideoPopup";
import PlayButton from "../../../assets/images/birds-in-music/play-button.png";
// import PauseButton from "../../../assets/images/birds-in-music/pause-button.png";

import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";


class VideoPlayer extends React.Component {
    constructor(props) {
    super(props);


    this.state = {
      url: this.props.url,  
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);



  }

  handleOpen(){
    this.setState({
        open:true
    });
  }

  handleClose(){
    
    this.setState({
        open:false
    });
  }
  
  render() {
    
    return (
      <div>
      <div className = {styles.BirdsInMusicVideoButton} styles = {styles.BirdsInMusicVideoButton} onClick = {this.handleOpen} >
        <div className = "video-player">
            <div className = {styles.BirdsInMusicPlayButton} styles = {styles.BirdsInMusicPlayButton}  >
                <img src = {PlayButton} style = {{width:"100%"}}></img>
            </div>

        </div>
        <p>Watch</p>
        </div>
        <VideoPopup 
                vurl= {this.state.url}
                close={this.handleClose}
                vopen={this.state.open}
        />
      </div>



        );
  }
}


export default VideoPlayer