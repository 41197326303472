import React from 'react';
import '../../../css/natures-symphony/Syrinx.css';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import syrinx from '../../../assets/images/natures-symphony/syrinx.png';
import syrinx22 from '../../../assets/images/natures-symphony/syrinx22.png';
import VideoPopup from './VideoPopup';

const Syrinx=()=>
{
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
return (
  <div className="mainSyrinx">
   
  <div className='syrinxHeading'>Do birds really sing?</div>
  <p className='syrinxPara'>Almost all known bird species have vocal abilities. While Ducks and Storks make simple calls, birds belonging to the group  Passerine  are true songbirds and can sing thousands of complex songs in perfect pitch and rhythm. There are over 5000 species of songbirds and some well-known Indian species including the Oriental Magpie-Robin, White-rumped Shama and Singing Bushlark.  
</p>
     <button className='videoButton' onClick={handleOpen}> <a><img className='videoIcon' src={videoButton}  alt='/' /></a>Watch a video</button>
     <VideoPopup 
       vurl="https://www.youtube.com/embed/I80rwURSnUg"
     
       close={handleClose}
       vopen={open}
       />
    <img className='syrinxImg hideshowhorizontal' src={syrinx} alt='/' />
    <img className='syrinxImg hideshowhorizontalmoble' src={syrinx22} alt='/' />
   </div>
);
}

export default Syrinx;
