import Slide3Img1 from "../../assets/images/birds-in-music/slide3-img1.png";
import Slide5Img1 from "../../assets/images/birds-in-music/slide5-img1.png";
import Slide5Img2 from "../../assets/images/birds-in-music/slide5-img2.png";
import Slide6Img1 from "../../assets/images/birds-in-music/slide6-img1.png";
import Slide6Img2 from "../../assets/images/birds-in-music/slide6-img2.png";
import Slide7Img1 from "../../assets/images/birds-in-music/slide7-img1.png";
import Slide7Img2 from "../../assets/images/birds-in-music/slide7-img2.png";
import Slide8Img1 from "../../assets/images/birds-in-music/slide8-img1.png";
import Slide8Img2 from "../../assets/images/birds-in-music/slide8-img2.png";
import Slide12Img1 from "../../assets/images/birds-in-music/slide12-img1.png";
import Slide9Img1 from "../../assets/images/birds-in-music/slide9-img1.png";
import Slide9Img2 from "../../assets/images/birds-in-music/slide9-img2.png";
import Slide10Img1 from "../../assets/images/birds-in-music/slide10-img1.png";
import Slide10Img2 from "../../assets/images/birds-in-music/slide10-img2.png";
import Slide11Img1 from "../../assets/images/birds-in-music/slide11-img1.png";
import Slide11Img2 from "../../assets/images/birds-in-music/slide11-img2.png";
var items = [
    {
        type:"Header",
        header: "A flight in Ragas",
        headerColor: "red",
        headerFontColor:"white",
        headerImage:{
            img:Slide3Img1
        } ,  
    },
    {
        type:"Content",
        contentHeader: "Birds in Indian Classical Music:\n An Introduction",
        content:<>Karnatik music compositions refer to birds as mounts (<i>vahanas</i>) of gods and goddesses. Hindustani music compositions are replete with bird symbols and metaphors. From Swans and Parrots to Peacocks and Koels, many compositions in Hindustani classical music have looked to birds for inspiration in narratives of love, seasons, devotion and struggle.</>,
        contenttwo:
                <>Several ragas in Indian classical music are named after birds. 
                While  Karnatik music has a number of such ragas, 
                Hindustani music has a few, such as <span className='fontitalic'> Hansakinkini</span> and <spna className="fontitalic">Vihang</spna> (literally meaning bird). 
                In most instances, the names of the ragas do not bear a direct connection to the birds they are named after.</>,
            
        contentColor: "red",
        contentFontColor:"white",
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/7y8d9f/Vinatha_Sutha_30_seca8q1l.mp3",
            description: "D. K. Jayaraman sings Vinatha Sutha, holding references to the legendary Garuda, son of Vinata.",
            credits : <>Credits: <i>Mahadeva (1983)</i></>
        },
        audioSectiontwo:{
            audioUrltwo:"https://mcdn.podbean.com/mf/web/b2q4d2/Koyalia_Bole_Drut_Bandish_30_sec6pede.mp3",
            description: "Pandit Dattatreya Vishnu Paluskar sings Koyaliya Bole, set in  Raag Tilak Kamod, and laid on the structure of teental.",
            credits : <>Credits: <i>Golden Milestones</i></>
        }       
    },
  
    {
        type:"Content",
        contentColors: "blue",
        contentHeader: <h1>Metaphors in Melody</h1>,
        contentColor: "blue",
        contentFontColor:"white",
        contentImage:{
            img:Slide5Img1,
            width:"41vh",
            left: "26%",
           
        } ,  
    },
    {
        type:"Content",
        contentHeader: "Peacocks",
        content:`Peacock imagery has often been associated with rainy weather and monsoons, and used to imply fertility in nature. 
                Hindustani compositions also mention the call of the peacock to connote a female’s desire to be reunited with her lover.`,
        contentColor: "blue",
        contentFontColor:"white",
        contentImage:{
                img:Slide5Img2,
            temp: "11"
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/uvy9jn/Peacock_30_sec9lsir.mp3",
            description: "A traditional bandish set in the raag Gaud Malhar, which is typically prescribed for the monsoon.",
            credits : <div><i>Name of composition : Balamaa bahaara aaee daadura bole<br/>Singer : DV Paluskar</i></div>
        }  
    },
    {
        type:"Content",
        contentHeader: "Koels",
        content:<>The Koel has been the muse of great poets and writers and is mentioned in numerous 
                <i> Thumri</i>, <i>Dadra, </i> and <i> Kajari</i> compositions of Hindustani music. 
                The song of the bird is often associated with monsoon and with narratives of forlorn lovers.</>,
        contentColor: "blue",
        contentFontColor:"white",
        contentImage:{
            img:Slide6Img1,
             temp: "11"
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/twzgjc/Koel-30_seca6ufx.mp3",
            description: <>A<i>dadra</i> that likens the Koel’s call to the stab of a knife into the heart of the forlorn lover."</>,
            credits : <div><i>Name of composition: Koyaliya Mat Kar Pukar<br/>Singer: Begum Akhtar</i></div>
        }  
    },
    {
        type:"Content",
        contentHeader: "Swans",
        content:<>Apart from ragas bearing the names of the swan such as Hansakinkini or Hamsadhwani, 
            the swan is used as a metaphor for Krishna in the 12th-century poet, Jayadeva's Ashtapadi and as a symbol for the individual spirit in the Nirguna music.</>,
        contentColor: "blue",
        contentFontColor:"white",
        contentImage:{
            img:Slide6Img2,
            temp: "11"
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/rk5dre/Swan-30_sec9q6qz.mp3",
            description: <>An <i>Ashtapadi</i>, written by Jayadeva, where the swan is a metaphor for Lord Krishna.</>,
            credits: <div><i>Name of composition: Ashtapadi<br />Singer : Jitendra Abhisheki</i></div>
        }  
    },
    {
        type:"Content",
        contentHeader: "Crow",
        content: <>Although crows are seen as the link between the worlds of the living and the dead in Indian mythology, 
                <i>Khayal</i> and <i>Dadra</i> compositions in Hindustani music frequently refer to the crow as a messenger of lovers.</>,
        contentColor: "blue",
        contentFontColor:"white",
        contentImage:{
            img:Slide7Img1,
            left:"35%",
            temp: "11"
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/4zk89k/Crow-30_secaxu27.mp3",
            description: <>
            
            A <i>dadra</i> that employs the image of a crow carrying a message to the beloved.",
            </>,
            credits : <div><i>Name of composition: Chhai Ghata Ghan Ghor<br/>Singer:  Shobha Gurtu</i></div>
        }  
    },
    {
        type:"Header",
        header: "Treasured in Folk Music",
        headerColor: "green",
        headerFontColor:"white",
        headerImage:{
            img:Slide7Img2,
           
        },  
    },
    {
        type:"Content",
        content:<>Folk music serves as intimate documentation of indigenous bird species, 
                their geographical spread, and their cultural significance to communities. 
                Folk songs of the Solegas who live in the Biligiri Rangan Hills of Karnataka are replete with 
                verses on the Swallow (whom they call <i>man-hakki</i> or mud bird),  Red-vented Bulbul, Spotted Dove, Koel, Peacock, Quail.</>,
        contentColor: "green",
        contentFontColor:"white",
        contentImage:{
            img:Slide8Img1,
           
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/qssmrf/Geena-_Solega_folk_30_sec7n0c8.mp3",
            description: "The Plum-headed Parakeet, also known as Geena in Solega parlance is one of many birds documented in the traditions of their singing.",
            credits : <div><i>Song: Geena<br/>Singers: Solegas from BR hill area</i></div>
        }  
    },
    {
        type:"Content",
        content:<>Rajastani folk songs feature the migratory bird, kurjan (Demoiselle Crane). 
                In Chhattisgarh, the Gond community has an entire collection of songs dedicated to the parrot called Sua Geet. 
            These songs are performed by Gond women in the post-Diwali harvest season even today.</>,
        contentColor: "green",
        contentFontColor:"white",
        contentImage:{
            img:Slide8Img2,
            
        },
        audioSection:{
            audioUrl:"https://mcdn.podbean.com/mf/web/kk5ms6/Sua_Geet_-30_sec6xc3n.mp3",
            description: "Women of the Gond community of Chattisgarh sing and dance to Sua Geet, celebrating the parrot, as they await the harvests of their land.",
            credits : <div><i>Song : Sua Geet <br /> Singer: Women of the Gond community, Rajasthan</i></div>
        }  
    },
    {
        type:"Header",
        header: "The Call of Celluloid",
        headerColor: "purple",
        headerFontColor:"white",
        headerImage:{
            img:Slide12Img1,
            temp: "11"
        } ,  
    },
    {
        type:"Content",
        content:<>Birds have ruled the roost in many Hindi film songs, from the <i>cheel</i> or Black Kite in Half Ticket to the
                <i>masakali</i>, or Indian fantail pigeon, in Delhi 6. They’ve been used as idioms (<i>Jhoot Bole Kauwa Kaate</i>,
                Bobby, 1978), romanticised (<i>Bole Re Papihara</i>, Guddi, 1971), celebrated as messengers of love
                (<i>Kabootar ja ja</i>, Maine Pyar Kiya, 1989), and used to symbolise free-spiritedness (<i>Panchi Banoon
            Udti Phiroon</i>, Chori Chori, 1956).</>,
        contentColor: "purple",
        contentFontColor:"white",
        videoSection:{
            videoUrl:"https://www.youtube.com/embed/56AUdC9mn4E",
            description: <>
            <i>Bole Re Papihara from the 1971 movie, Guddi, is sung by Vani Jairam and written by Gulzar.</i>,
            </>
        }  
    },
    {
        type:"Content",
        content:<>Birds have made many appearances in south Indian film music too. The <i>gili</i> (Parrot in Kannada),
                <i>Ramachilaka</i> (Rose-ringed Parakeet in Telugu), the <i>kuyil</i> (Koel in Tamil), and <i>neela ponman</i> (Kingfisher
            in Malayalam) have all been portrayed in songs as songsters or symbols of beauty and love.</>,
        contentColor: "purple",
        contentFontColor:"white",
        videoSection:{
            videoUrl:"https://www.youtube.com/embed/E0E-ftZ9kYY",
            description: <>
            <i>The song “Pullinangal” from the Tamil movie Robot 2.0 (2019) is a touching dedication to birds and their wordless symphonies.</i>
            </>,
        }  
    },
    {
        type:"Header",
        header: "Musical Instruments",
        headerColor: "white",
        headerFontColor:"black",
        headerImage: {
            img:Slide9Img1,
            top:"32%",
        },  
    },
    {
        type:"Content",
        contentHeader: "Taus",
        content:<>Also called the <i>Mayuri</i> veena after its peacock-shaped resonator, this string instrument is made of rosewood or tun wood. 
                The Taus is played with a bow made of horsehair. It is used as an accompaniment in Sikh devotional music called <i>Gurbani kirtan</i>.</>,
        contentColor: "white",
        contentFontColor:"black",
        contentImage:{
            img: Slide9Img2,
            temp: "11"
        },
        videoSection:{
            margin:"40%",
            videoUrl:"https://drive.google.com/file/d/12i0-w6bX9bnltqmQ-HIekMaD3SUm6_xg/preview",
            credits : <div>Credits: The Taus (‘Peacock’) | <br/>Sandeep Singh | Raag Ahir Bhairav| Darbar</div>
        }  
    },
    {
        type:"Content",
        contentHeader: "Tarpa",
        content:`This is a hornpipe mainly associated with the folk music tradition of western India. 
                Designed with a peacock head on one end and a broad funnel-like opening at the other, 
                the sonorous beauty of the  instrument shines through when it is played solo.`,
        contentColor: "white",
        contentFontColor:"black",
        contentImage:{
            img: Slide10Img1,
            bottom: "40%",
           
        },
        videoSection:{
            margin:"40%",
            videoUrl:"https://drive.google.com/file/d/1LWxbB0cYxS9BbtWp2PEfio3H1CGkmubw/preview",
            // credits : <div>Credits:The Tarpa | Folk Music of Dang | Ahwa from<br/>Gujarat | Sristi.org</div>
            credits : <div>Credits : Tarpa Player | Santosh Nimbalkar</div>
        }  
    },
    {
        type:"Content",
        contentHeader: "Bulbul Tarang",
        content:<>The name of this stringed instrument from Punjab literally translates to ‘waves of nightingales’. 
                It is considered to be an Indian version of the Japanese stringed instrument, 
                <i>Taishogoto</i>, and is used to accompany vocal music.</>,
        contentColor: "white",
        contentFontColor:"black",
        contentImage:{
            img: Slide10Img2,
            bottom:"45%",
            width: "30vh",
            left: "32%",
            temp: "12"

        },
        videoSection:{
            margin:"40%",
            videoUrl:"https://drive.google.com/file/d/1VDcbgkr2ph7bTaupWSWS-z7876r3YfRZ/preview",
            credits : "Credits: Bulbul Tarang | Unknown"
        }  
    },
    {
        type:"Content",
        contentHeader: "Mayuri Yaazh",
        content:`This is an open-stringed Harp that finds mention in ancient Tamil texts. 
                The instrument weighs around three kilograms and is made from the wood of the jackfruit tree. 
                It gets its name from its resonator that is shaped like a Peacock. The 12-stringed instrument is played vertically, 
                balanced on two sturdy clawed feet carved at its base.`,
        contentColor: "white",
        contentFontColor:"black",
        contentImage: {
            img: Slide11Img1,
            width:"30vh",
            left: "36%",
            temp: "11"
        },
        videoSection:{
            margin:"40%",
            videoUrl:"https://www.youtube.com/embed/31ZksEch0OE",
            credits : "Credits: The Sound of Yazh | Uru Instruments"
        }  
    },
    {
        type:"Content",
        contentHeader: "Bird Whistle",
        content:`A hand-held mouth-blown instrument, a bird whistle, is often made of terracotta, metal, wood, or plastic. 
                It can closely mimic the sound of a variety of bird species and can provide sounds with rhythmic accuracy. 
                Whistles and rattles shaped like the  Partridge or Dove have been found during excavations at Indus valley civilization sites.`,
        contentColor: "white",
        contentFontColor:"black",
        contentImage: {
            img: Slide11Img2,
            width:"30vh",
            left: "36%", 
            temp: "11"
        },
        videoSection:{
            margin:"40%",
            videoUrl:"https://drive.google.com/file/d/1WHUcwPWgoQxyIgxQFNPPVWXZAEY-1B46/preview",
            credits : "Credits: Terracotta bird whistle | Arnav"
        }  
    },
]

export default items;