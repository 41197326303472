import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import "../../../../css/natures-symphony/game/gameApp.css";
import logo from "../../../../assets/images/songs-of-nature/nature-logo.png";
import { SlideUp } from "../../../../animations/gameanime";

const LandingScreen = () => {
  return (
    <Container fluid className="LandingScreen">
      <Navbar id="son-nav">
        <NavbarBrand href="/menu">
          <img
            src={logo}
            width={93}
            height={80}
            alt="birdsong-logo"
            id="named-logo"
          />
        </NavbarBrand>
        <Nav className="ml-auto " navbar></Nav>
      </Navbar>
      <SlideUp>
        <div className="LandingScreenButton">
          <Row>
            <Col>
              <Link to={"/game"}>
                <Button className="WhiteButton" id="start-game-button">
                  Start Game
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </SlideUp>
    </Container>
  );
};

export default LandingScreen;
