import React from 'react';
import syrinx from '../../../assets/images/natures-symphony/syrinx.png';
import '../../../css/natures-symphony/Syrinx.css';
import videoButton from '../../../assets/images/natures-symphony/videoButton.png';
import strokeblue from '../../../assets/images/natures-symphony/strokeblue.png';
import strokeblue2 from "../../../assets/images/natures-symphony/strokeblue2.png";
import Factoidlotus from '../../../assets/images/natures-symphony/Factoidlotus.png';
import Factoidheading from '../../../assets/images/natures-symphony/Factoidheading.png';
import Courting from '../../../assets/images/natures-symphony/Courting.png';
import VideoPopup from './VideoPopup';


const SyrinxSkin = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="mainSyrinxSkin" >
      <img className='syrinxImg1' src={syrinx} alt='/' />

      <div className='syrinxHeading1'>How do birds sing?</div>
      <p className='syrinxPara' id='skinPara'>Birds vocalise through a special organ called the syrinx, located at the base of the windpipe. This is the bird’s voice box. Unlike the human larynx that can only produce one sound at a time, the syrinx allows birds to sing two tunes simultaneously.
      </p>

      <button className='videoButton' id='skinVideoButton' type='button' onClick={()=>{
        console.log("videoButton")
        handleOpen()
      }}>
        <a><img className='videoIcon' src={videoButton} alt='/' /></a>
        Watch a video
      </button>

      <VideoPopup
        vurl="https://www.youtube.com/embed/qKf-M5ijIvE"

        close={handleClose}
        vopen={open}
      />

      <img className='strokeBlue descohideshowimg' src={strokeblue} alt='/' />
      <img className="strokeBlue mobilehideshowimg" src={strokeblue2} alt="/" />

      <img className='factoidLotus' src={Factoidlotus} alt='/' />
      {/* ================ desktop screen ======================= */}

      <img className='factoidHeading desktopFactoid' src={Factoidheading} alt='/' />

      {/* ================ mobile and ipad  screen ===============  */}
      <div className="mobileipadFactoid">
        <h1>Factoid:</h1>
        <h2>Is it a call? Or song? </h2>
      </div>

      <p className='syrinxPara' id='factoidPara'> A bird call can be an alarm, or a signal to keep in contact while foraging for food or while in flight. A song is usually longer and more complex than a call, and is used to claim territory or performed during courtship. </p>
      <div className='syrinxHeading1' id='syrinxHeading2'>Why do songbirds sing?</div>
      <img className='courting' src={Courting} alt='/' />
    </div>
  );
}

export default SyrinxSkin;
