import React from "react";
import { Button, Col, Container, Navbar, NavbarBrand, Row } from "reactstrap";
import "../../css/landing-page/AboutUs.css";
import uLogo from "../../assets/images/landing-page/named-logo.png";
import cLine from "../../assets/images/landing-page/curatorial-underline.jpg";
import vLine from "../../assets/images/landing-page/vision-underline.jpg";
import aLine from "../../assets/images/landing-page/about-underline.jpg";
import pLine from "../../assets/images/landing-page/people-underline.jpg";
import imeLogo from "../../assets/images/landing-page/ime-logo.jpg";
import yuvaLogo from "../../assets/images/landing-page/yuva-logo.jpg";
import bcLogo from "../../assets/images/landing-page/british-council-logo.png";
import oschLogo from "../../assets/images/landing-page/osch-logo.png";
import sahana from "../../assets/images/landing-page/sahana.jpg";
import aditi from "../../assets/images/landing-page/aditi.jpg";
import amit from "../../assets/images/landing-page/amit.jpg";
import amrita from "../../assets/images/landing-page/amrita.jpg";
import rhea from "../../assets/images/landing-page/rhea.jpg";
import ananya from "../../assets/images/landing-page/ananya.jpg";
import yashvi from "../../assets/images/landing-page/yashvi.jpg";
import nishtha from "../../assets/images/landing-page/nishtha.jpg";
import khushi from "../../assets/images/landing-page/khushi.jpg";
import styles from "../../css/birds-in-music/BirdsInMusic.module.css";
import menu from "../../assets/images/birds-in-music/birdsong-menu.png";
import SectionMenu from "../navigation/SectionMenu";
import { useState } from "react";
import Customnavbar from "../Customnavbar/Customnavbar";

function AboutUs() {

  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }
  return (
    <Container id="about-us">
      {/* <Navbar>
        <NavbarBrand href="/menu">
          <img
            src={uLogo}
            width={93}
            height={80}
            alt="birdsong-logo"
            className="unnamed-logo"
          />
        </NavbarBrand>                  
      </Navbar>
          <Button className={styles.BirdsInMusicButton} id="menu-toggler-about" onClick={openMenu} >
            <img id="#menuIconfirst" src={menu}  />
          </Button> */}
          <Customnavbar imglogo={uLogo}  menuicon={menu}/>
      {/* <SectionMenu
        isActive={menuOpen}
        handleClose={closeMenu}
      /> */}
      <Row id="ab-sec-one">
        <span id="ab-heading">About Us</span>
      </Row>
      <Row id="ab-sec-two">
        <Col xs="12">
          <br />
          <span className="about-us-heading">Curatorial Note</span>
        </Col>
        <Col xs="12">
          <img src={cLine} alt="curatorial-note" />
        </Col>
        <Col xs="12">
          <p className="about-us-content">
            We believe that music has the ability to greatly enhance our
            understanding of the natural world.
            By using birdsong as a fresh
            thread of relatability, connecting humans to nature, the Birdsong
            exhibition explores the connection between music and the
            environment, through nature's most talented singers- the Songbird.
            The presence of birds in our environment has been vital to the
            health of our ecosystems, as well as the development of our art and
            culture. The idea that birds have been singing from the time of
            their presence on the planet, leads to the thought that perhaps the
            birdsong is the ancestor of human music itself. It is perhaps safe
            to consider that birds organically practised the musical art form,
            centuries before humans took to it for recreational inspiration.
          </p>
          <p  className="about-us-content">
             The
            Birdsong exhibition hopes to deepen our appreciation and mindfulness
            toward the environment we live in. It is a guided walk through the
            biological wonder of the songbird, the music they inspire, their
            prominence in Indian culture and history, and a dive into the
            fascinating world of songbirds that enrich the earth with their
            melodies.
          </p>
         
        </Col>
      </Row>
      <Row id="ab-sec-three">
        <Col xs="12">
          <span className="about-us-heading">
            Vision behind Birdsong - Virtual Exhibition
          </span>
        </Col>
        <Col xs="12">
          <img src={vLine} alt="vision" />
        </Col>

        <Col>
          <p className="about-us-content">
            Birdsong, originally curated and designed for a physical exhibit,
            has been open to the public at IME, from April to August 2022, in a microcosmic spread of the musical
            world of birds. However, the physical exhibition is constrained by
            its geographical location. This naturally led the translation of
            Birdsong into an online space, opening its interaction and access to
            a larger audience. (To know more, visit:
            <a href="https://indianmusicexperience.org/bird-song/">
              https://indianmusicexperience.org/bird-song/
            </a>{" "}
            )
            <br />
            <br />
            As the world progresses to integrating technology into the fields of
            art and culture, the IME proactively recognises this shift of
            artistic displays to virtually accessible platforms in the modern
            day. The museum took to mentor six volunteers in the crafts of
            curation, design and development for a period of 6 months in order
            to create a virtual exhibition. The fruit of this program manifested
            itself in the form of Birdsong, an original virtual exhibition that
            seeks to connect us with nature through the melody of songbirds.
            This interactive exhibit is a product of the FMPI (Future Museum
            Professionals Internship) team’s creative journey, through the
            months, as they skillfully interweaved elements of research, design
            and technology.
          </p>
        </Col>
      </Row>
      <Row id="ab-sec-four">
        <Col xs="12">
          <span className="about-us-heading">
            About Indian Music Experience
          </span>
        </Col>
        <Col xs="12">
          <img src={aLine} alt="about" />
        </Col>
        <Col>
          <p className="about-us-content">
            The Indian Music Experience Museum (IME) is India’s first
            interactive music museum. Located in JP Nagar, Bengaluru, the IME is
            a non-profit initiative supported by the Brigade Group. The vision
            of the IME is to introduce the youth to the diversity of Indian
            music and to preserve India’s rich musical heritage. With our
            multimedia Exhibit Galleries, a Sound Garden, Learning Centre for
            music education, and several performance spaces. The IME’s work
            spans across Exhibition, Conservation, Audience Development,
            Education and Community Outreach. The museum hosts a wide variety of
            public programs, both virtually and in-person.
          </p>
        </Col>
        <Col>
          {" "}
          <img src={imeLogo} alt="ime-logo" />{" "}
          <img src={yuvaLogo} alt="yuva-in-culture-logo" className="yuva-in-culture-logo" />
        </Col>
        <Col>
          <p className="about-us-content">
            {" "}
            #YuvaInCulture is a unique initiative of IME that engages the youth
            in leadership, creative work, and internships at the museum.
            Birdsong is a product of this program’s initiative to train a group
            of future museum professionals in the dynamic craft of composing
            exhibitions.
          </p>
        </Col>
      </Row>
      <Row id="ab-sec-five">
        <Col xs="12">
          <span className="about-us-heading">Program Partners</span>
        </Col>
        <Col xs="12">
          <img src={aLine} alt="about" />
        </Col>
        <Col>
          <p className="about-us-content">
            The British Council, an international organisation based in the
            United Kingdom, facilitates educational opportunities and cultural
            relations across the globe. The organisation works to connect the
            people of the UK and other countries through a culturally
            integrative range of programs and opportunities.
          </p>
        </Col>
        <Col>
          {" "}
          <img src={bcLogo} alt="british-council-logo" />{" "}
          <img src={oschLogo} alt="osch-logo" className="osch-logo" />
        </Col>
        <Col>
          <p className="about-us-content">
            {" "}
            Our Shared Cultural Heritage (OSCH) is a youth-led programme led by
            British Council that explores the shared cultures and histories of
            the UK and South Asia. The project works with young people aged
            11-25 to experiment and create new ways for museums and youth
            organisations to work together, become better places to explore
            identity, connect with others and create new opportunities for young
            people.
          </p>
        </Col>
      </Row>

      <Row id="ab-sec-six">
        <Col xs="12">
          <span className="about-us-heading">People</span>
        </Col>
        <Col xs="12">
          <img src={pLine} alt="people" />
        </Col>
        <Col>
          <p className="about-us-content">
            A group of six interns worked under the guidance of three mentors
            and content experts to bring the Birdsong website to life.
          </p>
        </Col>
        <Col xs="12">
          <span className="about-us-subheading">Mentors</span>
        </Col>
        <br />
        <Col  >
          {/* {" "}
          <img
            src={sahana}
            alt="sahana-mohan"
            width={230}
            height={285}
            className="mentor-image"
          />{" "}
          <img
            src={aditi}
            alt="aditi"
            width={230}
            height={285}
            className="mentor-image"
          />
          <img
            src={amit}
            alt="amit-goyal"
            width={230}
            height={285}
            className="mentor-image"
          /> */}
          <div className="ab-secmain-seven">

            <div className="seven-main">
              <div className="seven-main-first">
                <img
                  src={sahana}
                  alt="amrita-mukherjee"
                  width={230}
                  height={285}
                  className="intern-image"
                />
                {/* <div className="intern-profile"> */}
                <p className="intern-name">Sahana Mohan</p>
                <p className="intern-dept">Curatorial Mentor</p>
                {/* </div> */}
              </div>


              <div className="seven-main-first">
                <img
                  src={aditi}
                  alt="rhea-sokhanda"
                  width={230}
                  height={285}
                  className="intern-image"
                />
                {/* <div className="intern-profile"> */}
                <p className="intern-name">Aditi Dhamija</p>
                <p className="intern-dept">Design Mentor</p>
                {/* </div> */}
              </div>



              <div className="seven-main-first">
                <img
                  src={amit}
                  alt="ananya-s-r"
                  width={230}
                  height={285}
                  className="intern-image"
                />
                {/* <div className="intern-profile"> */}
                <p className="intern-name">Amit Goyal</p>
                <p className="intern-dept">Technical Mentor</p>
                {/* </div> */}
              </div>


            </div>

          </div>
        </Col>
      </Row>



      {/* <Row id="ab-sec-seven">
        <Col xs="12">
          <span className="about-us-subheading">Interns</span>
        </Col>
        <br />
        <Col>
          {" "}
          <a
            href="https://www.linkedin.com/in/amrita-mukherjee-81900718ree"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={amrita}
              alt="amrita-mukherjee"
              width={230}
              height={285}
              className="intern-image"
            />{" "}
          </a>
          <a
            href="https://www.linkedin.com/in/rhea-sokhanda-7a7458134"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={rhea}
              alt="rhea-sokhanda"
              width={230}
              height={285}
              className="intern-image"
            />
          </a>
          <a
            href="http://www.behance.net/ananya_roche/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={ananya}
              alt="ananya-s-r"
              width={230}
              height={285}
              className="intern-image"
            />
          </a>
        </Col>
        <Col className="intern-section">
          <div className="intern-profile">
            <a
              href="https://www.linkedin.com/in/amrita-mukherjee-81900718ree"
              target="_blank"
              rel="noreferrer"
            >
              <p className="intern-name">Amrita Mukherjee</p>
            </a>
            <p className="intern-dept">Curatorial Intern</p>
          </div>
          <div className="intern-profile">
            <a
              href="https://www.linkedin.com/in/rhea-sokhanda-7a7458134"
              target="_blank"
              rel="noreferrer"
            >
              <p className="intern-name">Rhea Sokhanda</p>
            </a>
            <p className="intern-dept">Curatorial Intern</p>
          </div>
          <div className="intern-profile">
            <a
              href="http://www.behance.net/ananya_roche/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="intern-name">Ananya S R</p>
            </a>
            <p className="intern-dept">Design Intern</p>
          </div>
        </Col>
        <Col>
          {" "}
          <a
            href="https://www.linkedin.com/in/yashvi-jain-b049851ba/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={yashvi}
              alt="yashvi-jain"
              width={230}
              height={285}
              className="intern-image"
            />{" "}
          </a>
          <a
            href="https://www.linkedin.com/in/nishtha-srivastavaa/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={nishtha}
              alt="nishtha-srivastava"
              width={230}
              height={285}
              className="intern-image"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/khushidubey12/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={khushi}
              alt="khushi-dubey"
              width={230}
              height={285}
              className="intern-image"
            />
          </a>
        </Col>
        <Col className="intern-section">
          <div className="intern-profile">
            <a
              href="https://www.linkedin.com/in/yashvi-jain-b049851ba/"
              target="_blank"
              rel="noreferrer"
              id="testing"
            >
              <p className="intern-name">Yashvi Jain</p>
            </a>
            <p className="intern-dept">Design Intern</p>
          </div>
          <div className="intern-profile">
            <a
              href="https://www.linkedin.com/in/nishtha-srivastavaa/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="intern-name">Nishtha Srivastava</p>
            </a>
            <p className="intern-dept">Development Intern</p>
          </div>
          <div className="intern-profile">
            <a
              href="https://www.linkedin.com/in/khushidubey12/"
              target="_blank"
              rel="noreferrer"
            >
              <p className="intern-name">Khushi Dubey</p>
            </a>
            <p className="intern-dept">Development Intern</p>
          </div>
        </Col>
      </Row> */}

      <Row id="ab-sec-seven" style={{ border: "0px solid red" }} >
        <Col xs="12" style={{border:"0px solid red"}}>
          <span className="about-us-subheading" id="Interns" >Interns</span>
        </Col>
        <div className="ab-secmain-seven">

          <div className="seven-main">
            <div className="seven-main-first">
              <a
                href="https://www.linkedin.com/in/amrita-mukherjee-81900718ree"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={amrita}
                  alt="amrita-mukherjee"
                  width={230}
                  height={285}
                  className="intern-image"
                />{" "}
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="https://www.linkedin.com/in/amrita-mukherjee-81900718ree"
                target="_blank"
                rel="noreferrer"
              >
                <p className="intern-name">Amrita Mukherjee</p>
              </a>
              <p className="intern-dept">Curatorial Intern</p>
              {/* </div> */}
            </div>


            <div className="seven-main-first">
              <a
                href="https://www.linkedin.com/in/rhea-sokhanda-7a7458134"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={rhea}
                  alt="rhea-sokhanda"
                  width={230}
                  height={285}
                  className="intern-image"
                />
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="https://www.linkedin.com/in/rhea-sokhanda-7a7458134"
                target="_blank"
                rel="noreferrer"
              >
                <p className="intern-name">Rhea Sokhanda</p>
              </a>
              <p className="intern-dept">Curatorial Intern</p>
              {/* </div> */}
            </div>



            <div className="seven-main-first">
              <a
                href="http://www.behance.net/ananya_roche/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={ananya}
                  alt="ananya-s-r"
                  width={230}
                  height={285}
                  className="intern-image"
                />
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="http://www.behance.net/ananya_roche/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="intern-name">Ananya S R</p>
              </a>
              <p className="intern-dept">Design Intern</p>
              {/* </div> */}
            </div>


          </div>

        </div>

        <div className="ab-secmain-seven">
          <div className="seven-main">
            <div className="seven-main-first">
              <a
                href="https://www.linkedin.com/in/yashvi-jain-b049851ba/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={yashvi}
                  alt="yashvi-jain"
                  width={230}
                  height={285}
                  className="intern-image"
                />{" "}
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="https://www.linkedin.com/in/yashvi-jain-b049851ba/"
                target="_blank"
                rel="noreferrer"
                id="testing"
              >
                <p className="intern-name">Yashvi Jain</p>
              </a>
              <p className="intern-dept">Design Intern</p>
              {/* </div> */}
            </div>


            <div className="seven-main-first">
              <a
                href="https://www.linkedin.com/in/nishtha-srivastavaa/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={nishtha}
                  alt="nishtha-srivastava"
                  width={230}
                  height={285}
                  className="intern-image"
                />
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="https://www.linkedin.com/in/nishtha-srivastavaa/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="intern-name">Nishtha Srivastava</p>
              </a>
              <p className="intern-dept">Development Intern</p>
              {/* </div> */}
            </div>



            <div className="seven-main-first">
              <a
                href="https://www.linkedin.com/in/khushidubey12/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={khushi}
                  alt="khushi-dubey"
                  width={230}
                  height={285}
                  className="intern-image"
                />
              </a>
              {/* <div className="intern-profile"> */}
              <a
                href="https://www.linkedin.com/in/khushidubey12/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="intern-name">Khushi Dubey</p>
              </a>
              <p className="intern-dept">Development Intern</p>
              {/* </div> */}
            </div>
          </div>
        </div>

      </Row>




      <br />
      <Row id="ab-sec-eight">
        <Col>
          {" "}
          <a
            href="https://www.britishcouncil.in/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={bcLogo} alt="british-council-logo" />{" "}
          </a>
          <a
            href="https://oursharedculturalheritage.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={oschLogo} alt="osch-logo" className="osch-logo" />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
