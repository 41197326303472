import React from "react";
import { Slider } from "./Slider";
import { items, itemsnew } from "./loreLegendData";
import logo from "../../assets/images/lore-and-legend/legend-logo.png";
import toggler from "../../assets/images/lore-and-legend/legend-menu.png";
import styles from "../../css/legend-and-lore/LegendLorePage.module.css";
import "../../css/legend-and-lore/LegendLorePage.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import AudioPlayer from "../../utils/AudioPlayer";
import SectionMenu from "../navigation/SectionMenu";
import GotoInloreLegends from "../natures-symphony/components/GotoInloreLegends";

import GoToBirdsinMusic from "../../assets/images/lore-and-legend/GoToBirdsinMusic.png";
import Customnavbar from "../Customnavbar/Customnavbar";

const name = require("../../assets/images/lore-and-legend/img-one.png");

var chataka1 = require("../../assets/images/lore-and-legend/chataka1.png");
var byngomaByangomi2 = require("../../assets/images/lore-and-legend/byngomaByangomi2.png");
var chakora3 = require("../../assets/images/lore-and-legend/chakora3.png");
var jatayu4 = require("../../assets/images/lore-and-legend/jatayu4.png");
var ganndberunda5 = require("../../assets/images/lore-and-legend/ganndberunda5.png");
var hansa6 = require("../../assets/images/lore-and-legend/hansa6.png");
var garuda7 = require("../../assets/images/lore-and-legend/garuda7.png");
var homa8 = require("../../assets/images/lore-and-legend/homa8.png");
var karuchasaras9 = require("../../assets/images/lore-and-legend/karuchasaras9.png");

var hansa = require("../../audio/Hansaaudio.mp3");


class LegendLorePage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      menuOpen: false,
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }
  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }
  render() {
    // const navigate = useNavigate();

    // const gotoBirdsInMusic=() => {
    //     navigate(`/lore`);
    // }
    return (
      <div className="flex fill center container" id="legend-lore">
        <div className={styles.LegendSlider}>
          {/* <div id="legend-lore-menu"> */}
            {/* <Button
              className={styles.LegendLoreButton}
              id="menu-toggler"
              onClick={this.openMenu}
            >
              <img src={toggler} height={"30"} width={"40"} />
            </Button>
            <Link to={"/menu"}>
              <Button className={styles.LegendLoreButton}>
                <img
                  src={logo}
                  alt="birdsong-logo"
                  height={"80"}
                  width={"90"}
                  className="unnamed-logo"
                />
              </Button>
            </Link> */}

            {/* <div className="sideBar">
              <Link to={"/menu"}>
                <img id="birdIcon" src={logo} alt="/" />
              </Link>

              <Button
                className="nature-symphony-button"
                id="menu-toggler"
                onClick={this.openMenu}
              >
                <img src={toggler} id="menuIconfirst" alt="menuIcon" />
              </Button>
            </div> */}
          {/* </div> */}
          <Customnavbar imglogo={logo}  menuicon={toggler}/>
          {/* =================DESKTOP================= */}
          <div className="loreshowhideDesktop">
            <Slider items={items} width={700} visible={3}>
              {({ css, audio }, i) => (
                <div className={styles.LegendLoreContent}>
                  <div
                    className={styles.LegendLoreImage}
                    style={{ backgroundImage: css }}
                  />
                  {audio !== undefined ? (
                    <AudioPlayer
                      index={i}
                      url={audio}
                      size={13}
                      className={styles.LegendLoreAudio}
                      buttonText={"Listen to audio story"}
                    />
                  ) : (
                    <span></span>
                  )}
                </div>
              )}
            </Slider>
          </div>
          {/* =================IPAD AND MOBILE================= */}
          <div className="loreshowhideMobile">
            <div className="mainsection"></div>
            <div className="mainsection2">
              <div className="LegendLoreimges">
                <div className="firstLegend">
                  <img src={name} height="100%" width="100%" />
                </div>
                <div className="secondLegend">
                  <p className="parasecondLegend">
                    Ancient Indian mythology and folklore is rich with tales of
                    birds, both real and imaginary.
                    <p />
                    <p className="parasecondLegend">
                      Mythical birds are symbols, often embodying various
                      aspects of divinity. Real birds too can be found in our
                      oldest stories and folktales, representing human
                      attributes such as kindness, vanity, greed and honesty and
                      even sacred qualities of associated gods and goddesses.
                    </p>
                    <p className="parasecondLegend">
                      In the mythical and real world, our feathered companions
                      have a lasting influence on our collective imagination.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div>
              {/* {itemsnew.map((newdaat) => {
                return (
                  <>
                    <div className="secondmainsection">
                      <div className="secondfirstmainsection">
                        <div className="conatantsection">
                          <h1
                            style={{ margin: "0" }}
                            className="titlefont"
                            id="firsttitlefont"
                          >
                            {newdaat.titel}
                          </h1>
                          <p className="titlepara">{newdaat.para}</p>
                          {newdaat.newaudio !== " " ? (
                            <AudioPlayer
                              url={newdaat.newaudio}
                              size={13}
                              className="LegendLoreAudio"
                              buttonText={"Listen to audio story"}
                            />
                          ) : (
                            " "
                          )}
                        </div>
                        <div className="conatantimagesection">
                          <img
                            src={newdaat?.imgnew}
                            width="100%"
                            height="100%"
                            id="firstimage"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })} */}

              {/* ============== statice pages ======================= */}
              <div className="lorcardmainsection">
                <h1 className="card-heading">Chataka</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={chataka1} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                        The mythical cuckoo <br></br> 
                        Born with a beak atop its head <br></br>
                        Awaits with extraordinary patience <br></br>
                        For the skies to open <br></br> 
                        And the first drops of water <br></br>
                        To quench its eternal thirst.
                    </p>
                    <AudioPlayer
                      // url={newdaat.newaudio}
                      url="https://mcdn.podbean.com/mf/web/txmq49/IME-SOMB-05-CHATAKA.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Byangoma-Byangomi</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={byngomaByangomi2} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      ...and so goes the tale in Thakurmar Jhuli <br></br> That  there live
                      two strange human- faced  birds<br></br> Blind since birth,<br></br>They come
                      armed with an incredible power <br></br>That allows them to see the
                      future for you and me <br></br> Lucky omens for the good - hearted <br></br> All
                      they need <br></br> to regain their sight <br></br> Are a few precious drops of
                      blood.
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/s78b4w/IME-SOMB-09-BYANGOMA-BYANGOMI.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Chakora</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={chakora3} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      Living on moonbeams <br></br> This winged creature holds a love most
                      sincere<br></br> For its dear Chandra.<br></br> Listen closely on a full
                      moon <br></br> You will hear this dreamy crow- pheasant<br></br> Singing its
                      heart out <br></br> for its waxing - and - waning beloved
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/xj26fe/IME-SOMB-07-CHAKORA.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Jatayu</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={jatayu4} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      The Eagle-faced one,<br></br>  Demi- god, <br></br> King of Vultures… <br></br> The son
                      of Aruni and Shyeni<br></br> And the nephew of Garuda <br></br> Has one - too
                      many names <br></br> But the valiant one will <br></br> Always be remembered
                      as<br></br> The one who fought Ravana<br></br> To save Rama’s love
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/9cht67/IME-SOMB-04-SAMPATI_JATAYU7nc3p.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Gandaberunda and Sharaba</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={ganndberunda5} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      Born with two heads <br></br> This avatar of Mahavishnu <br></br> Possesses
                      strength incredibly magical <br></br> Once the royal insignia <br></br> Of the
                      Vijayanagara empire and Mysore Wodeyars<br></br> It shines today as<br></br>
                      The state emblem of Karnataka.
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/rm5ygp/IME-SOMB-02-GANDABERUNDA_SHARABHA8nfds.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Hansa</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={hansa6} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      This celestial swan <br></br> Has pearls for breakfast, lunch and
                      dinner<br></br> The pious mount of Brahma and Saraswati<br></br> It has,
                      we’ve heard, one more power -<br></br>It can, like magic,<br></br> Separate
                      water from milk.
                    </p>
                    <AudioPlayer
                      url={hansa}
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Garuda</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={garuda7} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      Born to humans yet hatched out of an egg,<br></br>This kite- faced
                      deva <br></br>Stands bright and tall as the King of birds.<br></br>Vahana of
                      Lord Vishnu<br></br> And dreaded enemy of serpents <br></br>Blessed is he
                      with many superpowers<br></br> The swiftest of them all,<br></br>He can stop
                      the spinning of Earth, heaven and<br></br> hell<br></br> By merely flapping
                      his wings!
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/emwzbe/IME-SOMB-01-GARUDA.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Homa</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={homa8} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      Written about in the Vedas<br></br> Sung in Sufi poetry<br></br> And carved
                      on Tipu Sultan’s golden throne<br></br> This mystical bird<br></br> Is an
                      eternal resident of the skies<br></br> Catch a glimpse of it or its
                      shadow,<br></br> (An ancient prophecy foretells)<br></br> And you shall
                      become King!
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/psz6sr/IME-SOMB-08-HOMA_BIRDbgl22.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>

              <div className="lorcardmainsection">
                <h1 className="card-heading">Karucha Saras</h1>
                <div className="card-main-section">
                  <div className="card-img-section">
                    <img src={karuchasaras9} alt="" />
                  </div>
                  <div className="card-contant-section">
                    <p className="card-para">
                      A hunter shot at a crane couple<br></br> Valmiki lamented<br></br>
                      Sanskrit's first poem<br></br> Revered in many a folk song <br></br>The next
                      time you sight us<br></br> Wish for undying love.
                    </p>
                    <AudioPlayer
                      url="https://mcdn.podbean.com/mf/web/6xx5kk/IME-SOMB-06-KRAUNCHA.mp3"
                      size={13}
                      className="card-LegendLore-Audio"
                      buttonText={"Listen to audio story"}
                    />
                  </div>
                </div>
              </div>
              {/* ======end============ */}
            </div>

            {/* <button style={{border:"1px solid red",background:"red",position:"relative",bottom:"0"}}><Link to="/birdsinmusic">Birds In Music</Link></button> */}

            {/* <div
              style={{
                height: "15vh",
                width: "100vw",
                background: "red",
                padding: "0%",
                border: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "15vh",
                  padding: "0%",
                  border: "none",
                }}
              >
                <button
                  style={{
                    border: "none",
                    height: "15vh",
                    width: "100vw",
                    padding: "0%",
                    background: "#DE745E",
                  }}
                >
                  <Link to="/birdsinmusic">
                    <img
                      src={GoToBirdsinMusic}
                      style={{
                        height: "15vh",
                        width: "100vw",
                        padding: "0",
                        position: "relative",
                        bottom: "-8%",
                      }}
                    />
                  </Link>
                </button>
              </div>
            </div> */}

            <div
              style={{
                minHeight: "10vh",
                width: "100vw",
                background: "none",
                padding: "0%",
                border: "none",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  // height: "15vh",
                  padding: "0%",
                  border: "none",
                }}
              >
                <button
                  style={{
                    border: "none",
                    // height: "15vh",
                    width: "100vw",
                    padding: "0%",
                    background: "none",
                  }}
                >
                  <Link to="/birdsinmusic">
                    {/* <div style={{border:"1px solid red", height:"100%"}}>



                  </div> */}

                    <img
                      src={GoToBirdsinMusic}
                      style={{
                        height: "auto",
                        width: "100vw",
                        padding: "0",
                        position: "absolute",
                        left: "0",
                        // bottom: "0",
                      }}
                    />
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <SectionMenu
          isActive={this.state.menuOpen}
          handleClose={this.closeMenu}
        />
      </div>
    );
  }
}

export default LegendLorePage;
