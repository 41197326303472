import React, { useEffect, useState } from 'react'
import "../../../../css/natures-symphony/game/Modal.scss";
import classnames from "classnames";

function ModalComponent({card}) {
    const [isFlipped, setIsFlipped] = useState(false);
    useEffect(() => {
      setTimeout(() => {
          setIsFlipped(false)
      }, 2000);
      setIsFlipped(true)
    }, [])
    
    return (
        <div
          className={classnames("modal-container", {
            "is-flipped": isFlipped
          })}
        >
          <div className="modal-face modal-font-face">
            <img className="modal-img" src={card.backImage} alt="pokeball" />
          </div>
          <div className="modal-face modal-back-face">
            <img className="modal-img" src={card.frontImage} alt="pokeball" />
          </div>
        </div>
      );
}

export default ModalComponent