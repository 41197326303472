import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import GoToLorePage from '../../../assets/images/natures-symphony/GoToLorePage.png';


const GotoInloreLegends = () => {

    return (
        <div style={{
            minHeight: "13vh", width: "100vw", background: "#F4EACE", padding: "0%", border: "none",
        marginTop:"-14px",
        position:"relative" }}>
            <div style={{ width: "100%", height: "auto", padding: "0%", border: "none" }}>

                <button style={{ border: "none", height: "auto", width: "100vw", padding: "0%", background: "#F4EACE" }} >
                    <a href="/lore">
                        <img src={GoToLorePage} style={{ height: "auto", width: "101vw", padding: "0", position: "absolute",left:"0",top:"-14px",
                         bottom: "0%",
                         }} />
                    </a>
                </button>
            </div>
            {/* <div style={{ height: "18vh", width: "90vw", background: "blue", margin: "0 auto"  }}>
                <div style={{ height: "10vh", width: "90vw", display: "flex", justifyContent:"space-around",height:"auto",border:"1px solid red" }}>
                    <div style={{ border: "3px solid", borderRadius: "50px", width:"12%",  textAlign: "center" }}><h1>2</h1></div>
                    <div style={{textAlign: "center" }}><h1>In Lore & Langend</h1></div>
                </div>
                <div>
                    fgyhj
                </div>
            </div> */}

        </div>



    )
}

export default GotoInloreLegends