import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import { useEffect } from "react";
// import "./animations/script";

function App() {
 
  return (
    <div className="App">
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div>
  );
}

export default App;
