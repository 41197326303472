var name = require("../../assets/images/lore-and-legend/img-one.png");
var desc = require("../../assets/images/lore-and-legend/img-two.png");
var birdOne = require("../../assets/images/lore-and-legend/img-three.png");
var birdTwo = require("../../assets/images/lore-and-legend/img-four.png");
var birdThree = require("../../assets/images/lore-and-legend/img-five.png");
var birdFour = require("../../assets/images/lore-and-legend/img-six.png");
var birdFive = require("../../assets/images/lore-and-legend/img-seven.png");
var birdSix = require("../../assets/images/lore-and-legend/img-eight.png");
var birdSeven = require("../../assets/images/lore-and-legend/img-nine.png");
var birdEight = require("../../assets/images/lore-and-legend/img-ten.png");
var birdNine = require("../../assets/images/lore-and-legend/img-eleven.png");
var emptyBox = require("../../assets/images/lore-and-legend/img-twelve.png");

var chataka1 = require("../../assets/images/lore-and-legend/chataka1.png");
var byngomaByangomi2 = require("../../assets/images/lore-and-legend/byngomaByangomi2.png");
var chakora3 = require("../../assets/images/lore-and-legend/chakora3.png");
var jatayu4 = require("../../assets/images/lore-and-legend/jatayu4.png");
var ganndberunda5 = require("../../assets/images/lore-and-legend/ganndberunda5.png");
var hansa6 = require("../../assets/images/lore-and-legend/hansa6.png");
var garuda7 = require("../../assets/images/lore-and-legend/garuda7.png");
var homa8 = require("../../assets/images/lore-and-legend/homa8.png");
var karuchasaras9 = require("../../assets/images/lore-and-legend/karuchasaras9.png"); 

var hansa = require("../../audio/Hansaaudio.mp3");

export const items = [
  {
    css: "url(" + name + ")",
    height: 1000,
  },
  {
    css: "url(" + desc + ")",
    height: 300,
  },
  {
    css: "url(" + birdOne + ")",
    height: 300,
    audio: "https://mcdn.podbean.com/mf/web/txmq49/IME-SOMB-05-CHATAKA.mp3",
  },
  {
    css: "url(" + birdTwo + ")",
    height: 300,
    audio:
      "https://mcdn.podbean.com/mf/web/s78b4w/IME-SOMB-09-BYANGOMA-BYANGOMI.mp3",
  },
  {
    css: "url(" + birdThree + ")",
    height: 300,
    audio: "https://mcdn.podbean.com/mf/web/xj26fe/IME-SOMB-07-CHAKORA.mp3",
  },
  {
    css: "url(" + birdFour + ")",
    height: 300,
    audio:
      "https://mcdn.podbean.com/mf/web/9cht67/IME-SOMB-04-SAMPATI_JATAYU7nc3p.mp3",
  },
  {
    css: "url(" + birdFive + ")",
    height: 300,
    audio:
      "https://mcdn.podbean.com/mf/web/rm5ygp/IME-SOMB-02-GANDABERUNDA_SHARABHA8nfds.mp3",
  },
  {
    css: "url(" + birdSix + ")",
    height: 300,
    audio: hansa
  },
  {
    css: "url(" + birdSeven + ")",
    height: 300,
    audio: "https://mcdn.podbean.com/mf/web/emwzbe/IME-SOMB-01-GARUDA.mp3",
  },
  {
    css: "url(" + birdEight + ")",
    height: 300,
    audio:
      "https://mcdn.podbean.com/mf/web/psz6sr/IME-SOMB-08-HOMA_BIRDbgl22.mp3",
  },
  {
    css: "url(" + birdNine + ")",
    height: 300,
    audio: "https://mcdn.podbean.com/mf/web/6xx5kk/IME-SOMB-06-KRAUNCHA.mp3",
  },
  {
    css: "url(" + emptyBox + ")",
    height: 300,
  },
];


//  ipad and Mobile data =================================

export const itemsnew = [
  {
    titel: "Chataka",
    para: <>
    
    The mythical cuckoo <br></br> 
    Born with a beak atop its head <br></br>
     Awaits with extraordinary patience <br></br>
      For the skies to open <br></br> 
      And the first drops of water <br></br>
       To quench its eternal thirst.
    </>,
    imgnew: chataka1,
    newaudio: "https://mcdn.podbean.com/mf/web/txmq49/IME-SOMB-05-CHATAKA.mp3",
  },
  {
    titel: "Byangoma-Byangomi",
    para: "...and so goes the tale in Thakurmar Jhuli That there live two strange human- faced birdsBlind since birth,They come armed with an incredible powerThat allows them to see the future for you and meLucky omens for the good - heartedAll they needto regain their sightAre a few precious drops of blood.",
    imgnew: byngomaByangomi2,
    newaudio: "https://mcdn.podbean.com/mf/web/s78b4w/IME-SOMB-09-BYANGOMA-BYANGOMI.mp3",
  },
  {
    titel: "Chakora",
    para: "Living on moonbeams This winged creature holds a love most sincere For its dear Chandra.  Listen closely on a full moon You will hear this dreamy crow- pheasant Singing its heart out for its waxing - and - waning beloved",
    imgnew: chakora3,
    newaudio: "https://mcdn.podbean.com/mf/web/xj26fe/IME-SOMB-07-CHAKORA.mp3",
  },
  {
    titel: "Jatayu",
    para: "The Eagle-faced one, Demi- god,King of Vultures… The son of Aruni and Shyeni And the nephew of Garuda Has one - too many names But the valiant one will Always be remembered as The one who fought Ravana To save Rama’s love",
    imgnew: jatayu4,
    newaudio: "https://mcdn.podbean.com/mf/web/9cht67/IME-SOMB-04-SAMPATI_JATAYU7nc3p.mp3",
  },
  {
    titel: "Gandaberunda and Sharaba",
    para: " Born with two heads This avatar of Mahavishnu Possesses strength incredibly magical Once the royal insignia Of the Vijayanagara empire and Mysore Wodeyars It shines today as The state emblem of Karnataka.",
    imgnew: ganndberunda5,
    newaudio: "https://mcdn.podbean.com/mf/web/rm5ygp/IME-SOMB-02-GANDABERUNDA_SHARABHA8nfds.mp3",
  },
  {
    titel: "Hansa",
    para: "This celestial swan Has pearls for breakfast, lunch and dinner The pious mount of Brahma and Saraswati It has, we’ve heard, one more power –It can, like magic, Separate water from milk.",
    imgnew: hansa6,
    newaudio: " ",
  },
  {
    titel: "Garuda",
    para: "Born to humans yet hatched out of an egg,This kite- faced deva Stands bright and tall as the King of birds.Vahana of Lord Vishnu And dreaded enemy of serpents Blessed is he with many superpowers The swiftest of them all,He can stop the spinning of Earth, heaven and hell By merely flapping his wings!",
    imgnew: garuda7,
    newaudio: "https://mcdn.podbean.com/mf/web/emwzbe/IME-SOMB-01-GARUDA.mp3",
  },
  {
    titel: "Homa",
    para: "Written about in the Vedas Sung in Sufi poetry And carved on Tipu Sultan’s golden throne This mystical bird Is an eternal resident of the skies Catch a glimpse of it or its shadow, (An ancient prophecy foretells) And you shall become King!",
    imgnew: homa8,
    newaudio: "https://mcdn.podbean.com/mf/web/psz6sr/IME-SOMB-08-HOMA_BIRDbgl22.mp3",
  },
  {
    titel: "Karucha Saras",
    para: "A hunter shot at a crane couple Valmiki lamented Sanskrit's first poem Revered in many a folk song The next time you sight us Wish for undying love.",
    imgnew: karuchasaras9,
    newaudio: "https://mcdn.podbean.com/mf/web/6xx5kk/IME-SOMB-06-KRAUNCHA.mp3",
  },
]

