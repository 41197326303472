import React from 'react';
import '../../../css/natures-symphony/Tutor.css';
import smallbird from '../../../assets/images/natures-symphony/smallbird.png';
import smallbirdlayer from '../../../assets/images/natures-symphony/smallbirdlayer.png';
import twobird from '../../../assets/images/natures-symphony/twobird.png'; 

const TutorGrey=()=>
{
return (
  <div className="mainTutorGrey"

//    style={{display:'flex',
//    width: '60.17vw',
//  position: 'relative',
//  overflow: 'hidden',
//  zIndex: '-1',
//   height:'100vh',
// backgroundColor:'#333333' }}
>
   
  <div className='TutorHeading'>Are songbirds born singers or do they have tutors?</div>
  {/* =============== desktop show ================ */}
  <img  className='smallBird descohideshowimg' src={smallbird} alt='/' />
  <img  className='smallBirdLayer descohideshowimg' src={smallbirdlayer} alt='/' />
 
    {/* =============== mobile show ================ */}
    <img className='smallBird mobilehideshowimg ' src={twobird} alt='/' />


   </div>
);
}

export default TutorGrey;
