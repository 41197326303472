/* Template for slide with header on the left side and content on the right side */
import React from 'react';
import styles from"../../../css/birds-in-music/BirdsInMusic.module.css";
import ContentColumn from './ContentColumn';
import HeaderColumn from './HeaderColumn';



class BirdsInMusicSlide extends React.Component {
    constructor(props){
        super(); 
        this.data = props.data;
        this.leftPanel = this.data.leftPanel;
        this.rightPanel = this.data.rightPanel;
        this.requestAudioPlayback = props.requestAudioPlayback;
        this.requestAudioPause = props.requestAudioPause;
        this.state = {
            leftPlaybackComponent: props.audioPlaybackComponents[this.leftPanel.index]?props.audioPlaybackComponents[this.leftPanel.index]:null,
            rightPlaybackComponent: props.audioPlaybackComponents[this.rightPanel.index]?props.audioPlaybackComponents[this.rightPanel.index]:null
        }
        

    }
    componentDidUpdate(prevProps){
        if(this.state.leftPlaybackComponent != this.props.audioPlaybackComponents[this.leftPanel.index]){
            this.setState({
                leftPlaybackComponent:this.props.audioPlaybackComponents[this.leftPanel.index]
            });
        }
        if(this.state.rightPlaybackComponent != this.props.audioPlaybackComponents[this.rightPanel.index]){
            this.setState({
                rightPlaybackComponent:this.props.audioPlaybackComponents[this.rightPanel.index]
            });
        }
    
      }
    

    render(){
        return (
            <div className = {styles.BirdsInMusicSlide} styles = {styles.BirdsInMusicSlide}>
                <div className = {styles.BirdsInMusicSlideColumns} styles = {styles.BirdsInMusicSlideColumns}>
                    {
                        this.leftPanel?this.leftPanel.type == "Header"?
                        <HeaderColumn
                            index = {this.leftPanel.index}
                            header = {this.leftPanel.header}
                            headerColor = {this.leftPanel.headerColor}
                            headerFontColor = {this.leftPanel.headerFontColor}
                            headerImage = {this.leftPanel.headerImage}
                        />:

                        <ContentColumn
                            index = {this.leftPanel.index}
                            contentHeader = {this.leftPanel.contentHeader}
                            content = {this.leftPanel.content}
                            // contenttwo= {this.leftPanel.contenttwo}
                            contentColor = {this.leftPanel.contentColor}
                            contentFontColor = {this.leftPanel.contentFontColor}
                            contentImage = {this.leftPanel.contentImage}
                            contentAudioSection = {this.leftPanel.audioSection}
                            contentVideoSection = {this.leftPanel.videoSection}
                            requestAudioPlayback = {this.requestAudioPlayback}
                            requestAudioPause = {this.requestAudioPause}
                            audioConfig = {this.state.leftPlaybackComponent}
                        />:
                        <div className = {styles.BirdsInMusicHeaderColumn} styles = {styles.BirdsInMusicHeaderColumn}>
                        </div>
                    }
                    {
                        this.rightPanel?this.rightPanel.type == "Header"?
                        <HeaderColumn
                            index = {this.rightPanel.index}
                            header = {this.rightPanel.header}
                            headerColor = {this.rightPanel.headerColor}
                            headerFontColor = {this.rightPanel.headerFontColor}
                            headerImage = {this.rightPanel.headerImage}
                        />:

                        <ContentColumn
                            index = {this.rightPanel.index}
                            contentHeader = {this.rightPanel.contentHeader}
                            content = {this.rightPanel.content}
                            contenttwo= {this.rightPanel.contenttwo}
                            contentColor = {this.rightPanel.contentColor}
                            contentFontColor = {this.rightPanel.contentFontColor}
                            contentImage = {this.rightPanel.contentImage}
                            contentAudioSection = {this.rightPanel.audioSection}
                            contentAudioSectiontwo = {this.rightPanel.audioSectiontwo}
                            contentVideoSection = {this.rightPanel.videoSection}
                            requestAudioPlayback = {this.requestAudioPlayback}
                            requestAudioPause = {this.requestAudioPause}
                            audioConfig = {this.state.rightPlaybackComponent}
                            
                        />:
                        <div className = {styles.BirdsInMusicContentColumn} styles = {styles.BirdsInMusicContentColumn}>
                        </div>
                    }

                </div>

            </div>
        )
    }
}

export default BirdsInMusicSlide;