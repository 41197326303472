import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./landing-page/AboutUs";
import Birds from "./songs-of-nature/Birds";
import LandingPage from "./landing-page/LandingPage";
import Menu from "./landing-page/Menu";
import LegendLorePage from "./lore-and-legend/LegendLorePage";
import BirdsInMusic from "./birds-in-music/BirdsInMusic";
import SonIntroPage from "./songs-of-nature/SonIntroPage";
import NatureSymphony from "./natures-symphony/components/NatureSymphony";
import LandingScreen from "../pages/natures-symphony/components/game_components/LandingScreenComponent";
import ExitScreen from "../pages/natures-symphony/components/game_components/ExitScreen";
import Game from "../pages/natures-symphony/components/game_components/Game";
// import Disclaimer from "./landing-page/Disclaimer";
import DisclaimerMobile from "./landing-page/DisclaimerMobile";
import Customnavbar from "./Customnavbar/Customnavbar";

function Main() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route  path="/menu" element={<Menu />} />
        <Route exact path="/lore" element={<LegendLorePage />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/birdsinmusic" element={<BirdsInMusic />} />
        <Route exact path="/son" element={<SonIntroPage />} />
        <Route path="/birds/:birdId" element={<Birds />} />
        <Route path="/naturesymphony" element={<NatureSymphony />} />
        <Route path="/gameLand" element={<LandingScreen />} />
        <Route exact path="/game" element={<Game />} />
        <Route exact path="/exit" element={<ExitScreen />} />
        <Route exact path="/disclaimerMobile" element={<DisclaimerMobile />} />
        <Route exact path="/Customnavbar" element={<Customnavbar  />} />
      </Routes>
    </React.Fragment>
  );
}

export default Main;
