import React from 'react'
import "../../../../css/natures-symphony/game/Instruct.scss"
function FunFactsContent({card1, card2}) {
    
    return (
      <div>
        <div className='funFactsDiv'>
        <p className='funFacts'>{card1.funFact}</p>
        <p className='funFacts'>{card2.funFact}</p>
        </div>
        
        </div>
      );
}

export default FunFactsContent